import {Colors, Divider, Icon, Spinner} from '@blueprintjs/core';
import {delay, run} from 'abort-controller-x';
import {format} from 'date-fns';
import {isEqual} from 'lodash';
import React, {useEffect, useState} from 'react';
import {stylesheet, classes as cx} from 'typestyle';
import {useExchequerClient} from '../../client/useExchequerClient';
import {rootLogger} from '../../logger';
import {Term} from '../../proto/deeplay/jackpoker_exchequer/v1/deal';
import {
  GetDealHistoryResponse_DealVersion,
  GetLinkHistoryResponse_LinkVersion,
} from '../../proto/deeplay/jackpoker_exchequer/v1/jackpoker_exchequer';
import {fromProtoMoney} from '../../utils/moneyMapper';

const css = stylesheet({
  history: {
    minHeight: 'min-content',
    maxHeight: 'calc(100vh - 50px)',
    overflowY: 'scroll',
    paddingRight: 10,
    borderLeft: `1px solid ${Colors.BLACK}`,
    paddingLeft: 20,
  },
  withPadding: {
    paddingLeft: 20,
  },
  boldText: {
    fontWeight: 'bold',
  },
  lightText: {
    color: Colors.GRAY1,
  },
  alignedRight: {
    textAlign: 'end',
  },
  header: {
    marginTop: 25, // To align with 'edit deal' header which has buttons in it
    textAlign: 'center',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

type VersionHistoryProps = {
  dealId: string;
  lastLinkId?: string;
  affiliateId: string | null;
};

export const DealAndLinkVersionHistory: React.FC<VersionHistoryProps> = ({
  affiliateId,
  dealId,
  lastLinkId,
}) => {
  const exchequerClient = useExchequerClient();

  const [history, setHistory] = useState<
    null | GetDealHistoryResponse_DealVersion[]
  >(null);

  const [linkHistory, setLinkHistory] = useState<
    null | GetLinkHistoryResponse_LinkVersion[]
  >(null);

  useEffect(() => {
    const stop = run(async signal => {
      while (!signal.aborted) {
        try {
          const {items} = await exchequerClient.getDealHistory(
            {dealId},
            {signal},
          );

          setHistory(oldItems => {
            if (!isEqual(oldItems, items)) {
              return items;
            } else {
              return oldItems;
            }
          });
        } catch (err) {
          rootLogger.warn({err}, 'Failed to fetch deal history');
        } finally {
          await delay(signal, 10_000);
        }
      }
    });

    return () => {
      stop();
    };
  }, [dealId, exchequerClient]);

  useEffect(() => {
    const stop = run(async signal => {
      if (!lastLinkId) {
        return;
      }

      while (!signal.aborted) {
        try {
          const {items} = await exchequerClient.getLinkHistory(
            {linkId: lastLinkId, dealId},
            {signal},
          );
          setLinkHistory(oldItems => {
            if (!isEqual(oldItems, items)) {
              return items;
            } else {
              return oldItems;
            }
          });
        } catch (err) {
          rootLogger.warn({err}, 'Failed to fetch link history');
        } finally {
          await delay(signal, 10_000);
        }
      }
    });

    return () => {
      stop();
    };
  }, [exchequerClient, lastLinkId, dealId]);

  if (!history) {
    return (
      <div className={css.spinner}>
        <Spinner size={30} />
      </div>
    );
  }

  return (
    <div className={css.history}>
      <h4 className={css.header}>Deal History</h4>
      <p>Affiliate ID: {affiliateId || ''}</p>
      {renderDealHistory(history)}
      {linkHistory && (
        <>
          <h4 className={css.header}>Link History</h4>
          <p>Link ID: {lastLinkId}</p>
          {renderLinkHistory(linkHistory)}
        </>
      )}
    </div>
  );
};

const formatDateConfig = 'dd/MM/yyyy';

const renderDealHistory = (
  history: GetDealHistoryResponse_DealVersion[],
): JSX.Element[] | null => {
  if (!history.length) {
    return null;
  }

  let elements: JSX.Element[] = [];

  for (let i = 0; i < history.length; i += 1) {
    const historyDoc = history[i];

    if (!historyDoc) {
      continue;
    }

    const {deal, deleted, updatedAt} = historyDoc;
    if (!deal) {
      continue;
    }

    if (i === history.length - 1) {
      const element = (
        <div key="created-at">
          Document created{' '}
          {updatedAt ? format(updatedAt, 'dd/MM/yyyy H:mm:ss') : '?'}
        </div>
      );
      elements = [element, ...elements];
    }

    const element = (
      <div key={i}>
        {i === 0 && (
          <h5 className={css.alignedRight}>
            <Icon icon="updated" intent="success" /> Latest version
          </h5>
        )}
        {i !== 0 && deleted && (
          <h5 className={css.alignedRight}>
            <Icon icon="delete" intent="danger" /> Deleted version
          </h5>
        )}
        <p className={cx(css.lightText, css.alignedRight)}>
          Updated {updatedAt ? format(updatedAt, 'dd/MM/yyyy H:mm:ss') : '?'}
        </p>
        <p>Name: {deal.name}</p>

        <div>
          <div>
            {deal.terms.map((term, index) => {
              return (
                <div key={index}>
                  <p className={css.boldText}>
                    {term.startDate
                      ? format(term.startDate, formatDateConfig)
                      : '?'}{' '}
                    -{' '}
                    {term.endDate
                      ? format(term.endDate, formatDateConfig)
                      : '?'}
                  </p>
                  <p className={css.withPadding}>
                    Type: {formatTermName(term)}
                  </p>
                  {term.type?.$case === 'cpa' ? (
                    <div className={css.withPadding}>
                      <p>
                        Payment Amount:{' '}
                        {fromProtoMoney(term.type.cpa.paymentAmount)}
                      </p>
                      <p>
                        Target Wager:{' '}
                        {fromProtoMoney(term.type.cpa.targetWager)}
                      </p>
                      <p>
                        Minimal Deposit:{' '}
                        {fromProtoMoney(term.type.cpa.minimalDeposit)}
                      </p>
                    </div>
                  ) : (
                    <p className={css.withPadding}>
                      Deal Percentage: {getDealPercentage(term)}%
                    </p>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        {i !== history.length - 1 && <Divider />}
      </div>
    );

    elements.push(element);
  }

  return elements;
};

const renderLinkHistory = (
  history: GetLinkHistoryResponse_LinkVersion[],
): JSX.Element[] | null => {
  if (!history.length) {
    return null;
  }

  let elements: JSX.Element[] = [];

  for (let i = 0; i < history.length; i += 1) {
    const historyDoc = history[i];

    if (!historyDoc) {
      continue;
    }

    const {link, deleted, updatedAt} = historyDoc;
    if (!link) {
      continue;
    }

    if (i === history.length - 1) {
      const element = (
        <div key="created-at">
          Document created{' '}
          {updatedAt ? format(updatedAt, 'dd/MM/yyyy H:mm:ss') : '?'}
        </div>
      );
      elements = [element, ...elements];
    }

    const element = (
      <div key={i}>
        {i === 0 && (
          <h5 className={css.alignedRight}>
            <Icon icon="updated" intent="success" /> Latest version
          </h5>
        )}
        {i !== 0 && deleted && (
          <h5 className={css.alignedRight}>
            <Icon icon="delete" intent="danger" /> Deleted version
          </h5>
        )}
        <p className={cx(css.lightText, css.alignedRight)}>
          Updated {updatedAt ? format(updatedAt, 'dd/MM/yyyy H:mm:ss') : '?'}
        </p>
        <p>Link: {link.content}</p>
        <p>
          Start Date:{' '}
          {link.startDate ? format(link.startDate, formatDateConfig) : '?'}{' '}
        </p>
      </div>
    );

    elements.push(element);
  }

  return elements;
};
const formatTermName = (term: Term) => {
  if (term.type?.$case === 'cpa') {
    return 'CPA';
  }
  if (term.type?.$case === 'revenueShareDepositCashoutBased') {
    return 'Revenue Share (Deposit/Cashout Based)';
  }
  if (term.type?.$case === 'revenueShareLossWinRakeBased') {
    return 'Revenue Share (Loss/Win/Rake Based)';
  }

  if (term.type?.$case === 'revenueShareRakeBased') {
    return 'Revenue Share (Rake Based)';
  }
  if (term.type?.$case === 'revenueShareWinLossBased') {
    return 'Revenue Share (Win/Loss Based)';
  }

  return '';
};

const getDealPercentage = (term: Term) => {
  if (term.type?.$case === 'revenueShareDepositCashoutBased') {
    return (
      (term.type.revenueShareDepositCashoutBased.dealPercentage /
        1_000_000_000) *
      100
    );
  }
  if (term.type?.$case === 'revenueShareLossWinRakeBased') {
    return (
      (term.type.revenueShareLossWinRakeBased.dealPercentage / 1_000_000_000) *
      100
    );
  }

  if (term.type?.$case === 'revenueShareRakeBased') {
    return (
      (term.type.revenueShareRakeBased.dealPercentage / 1_000_000_000) * 100
    );
  }

  if (term.type?.$case === 'revenueShareWinLossBased') {
    return (
      (term.type.revenueShareWinLossBased.dealPercentage / 1_000_000_000) * 100
    );
  }

  return 0;
};
