import * as rt from 'runtypes';

export const AppConfig = rt.Record({
  oidc: rt.Record({
    issuerUrl: rt.String,
    clientId: rt.String,
    scopes: rt.Array(rt.String),
  }),
});

export type AppConfig = rt.Static<typeof AppConfig>;

export const appConfig = AppConfig.check((window as any).appConfig);
