import {Money} from '../proto/deeplay/common/money';

export const toProtoMoney = (amount?: number): Money => {
  if (!amount) {
    return Money.fromPartial({
      units: 0,
      nanos: 0,
    });
  }

  const factor = amount >= 0 ? 1 : -1;

  const arrAmount = Math.abs(amount).toString().split('.');

  return Money.fromPartial({
    units: parseInt(arrAmount[0]!) * factor,
    nanos: (arrAmount[1] ? parseFloat(`0.${arrAmount[1]}`) : 0) * 1e9 * factor,
  });
};

export const fromProtoMoney = (amount?: Money): number => {
  if (!amount) {
    return 0;
  }

  return amount.units.toNumber() + amount.nanos / 1e9;
};

export const stringFromProtoMoney = (amount?: Money): string => {
  if (!amount) {
    return '0';
  }

  return `${amount.units.toNumber() + amount.nanos / 1e9}`;
};
