import {Icon} from '@blueprintjs/core';
import {PropsWithChildren, useCallback} from 'react';
import {classes, stylesheet} from 'typestyle';
import {TableSort} from '../hooks/useTableSort';

export type SortableColumnHeaderProps<ColumnName extends string> = {
  className?: string;
  name: ColumnName;
  sort: TableSort<ColumnName>;
};

const css = stylesheet({
  root: {
    cursor: 'pointer',
  },

  title: {
    textDecoration: 'underline dotted',
  },
});

export function SortableColumnHeader<ColumnName extends string>({
  className,
  name,
  sort,
  children,
}: PropsWithChildren<SortableColumnHeaderProps<ColumnName>>) {
  const {column, order, onColumnClick} = sort;

  const handleClick = useCallback(() => {
    onColumnClick(name);
  }, [name, onColumnClick]);

  return (
    <th className={classes(css.root, className)} onClick={handleClick}>
      <span className={css.title}>{children}</span>{' '}
      <Icon
        icon={
          column === name
            ? order === 'asc'
              ? 'sort-asc'
              : 'sort-desc'
            : 'blank'
        }
      />
    </th>
  );
}
