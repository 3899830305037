import React, {memo, useEffect, useMemo, useState} from 'react';

import {stylesheet, classes as cx} from 'typestyle';
import {important} from 'csx';
import {Colors, HTMLTable} from '@blueprintjs/core';
import {useTableSort} from '../../hooks/useTableSort';
import {isEqual, last, ListIteratee, orderBy, sortBy} from 'lodash';
import {invoke} from '../../utils/invoke';
import assertNever from 'assert-never';
import {SortableColumnHeader} from '../../components/SortableColumnHeader';

import {Link} from '../../proto/deeplay/jackpoker_exchequer/v1/link';
import {run} from 'abort-controller-x';
import {useExchequerClient} from '../../client/useExchequerClient';
import {subscribeToLinks} from '../../subscriptions/linksSubscription';
import {format} from 'date-fns';
import {
  UsersContextState,
  useUsersObservableContext,
  INITIAL_STATE,
} from '../../subscriptions/usersObservableContext';
import {useObservableState} from 'observable-hooks';

const css = stylesheet({
  tableWrapper: {
    height: '100%',
    overflowY: 'scroll',
    margin: '0 10px',
    border: `1px solid ${Colors.DARK_GRAY2}`,
  },
  tableHead: {
    position: 'sticky',
    top: 0,
    backgroundColor: Colors.WHITE,
    boxShadow: '0 1px 0 0 rgb(16 22 26 / 15%)',
    $nest: {
      '& tr th': {
        textAlign: 'start',
      },
    },
  },

  table: {
    width: '100%',
    tableLayout: 'fixed',
  },

  tableBody: {
    $nest: {
      '& tr:first-child td': {
        boxShadow: important('none'),
      },
    },
  },
  noPadding: {
    padding: important(0),
  },
  deal: {
    padding: 10,
  },
  inactive: {
    color: Colors.GRAY3,
  },
});

type SortColumn = 'link' | 'affiliate-id' | 'affiliate-name' | 'deals';

export type LinksListProps = {};

/**
 * Links List - это список всех актуальных линков, которые хранятся в базе.
 */
export const LinksList: React.FC<LinksListProps> = memo(() => {
  const [linksById, setLinksById] = useState<Map<string, Link> | null>(null); // FIXME: maybe show spinner while links are loading?
  const exchequerClient = useExchequerClient();
  const observable$ = useUsersObservableContext();
  const [{usersById}] = useObservableState<UsersContextState>(
    () => observable$,
    INITIAL_STATE,
  );

  useEffect(() => {
    const stop = run(async signal => {
      for await (const updates of subscribeToLinks(signal, exchequerClient)) {
        setLinksById(links => {
          const newLinks: Map<string, Link> = new Map(links);
          for (const {key, value} of updates) {
            if (value != null) {
              newLinks.set(key, value);
            } else {
              newLinks.delete(key);
            }
          }

          if (!isEqual(links, newLinks)) {
            return newLinks;
          } else {
            return links;
          }
        });
      }
    });

    return () => {
      stop();
    };
  }, [exchequerClient]);

  const sort = useTableSort<SortColumn>('link');

  const sortedLinks = useMemo(() => {
    const iteratee = invoke(
      (): ListIteratee<Link> => {
        switch (sort.column) {
          case 'link':
            return link => link.content;
          case 'affiliate-id':
            return link => link.affiliateId;
          case 'affiliate-name':
            return link => {
              const affiliateUser = usersById.get(link.affiliateId);
              return affiliateUser?.name;
            };
          case 'deals':
            return link => {
              const latestDeal = last(
                sortBy(link.assignedDeals, deal => deal.startDate) || [],
              );
              return latestDeal?.startDate;
            };
          default:
            return assertNever(sort.column);
        }
      },
    );

    return orderBy([...(linksById?.values() ?? [])], iteratee, sort.order);
  }, [linksById, sort.column, sort.order, usersById]);

  return (
    <div className={css.tableWrapper}>
      <HTMLTable striped className={css.table}>
        <colgroup>
          <col style={{width: '150px'}}></col>
          <col style={{width: '100px'}}></col>
          <col style={{width: '250px'}}></col>
          <col style={{width: '250px'}}></col>
        </colgroup>

        <thead className={css.tableHead}>
          <tr>
            <SortableColumnHeader name="link" sort={sort}>
              <span>Link</span>
            </SortableColumnHeader>

            <SortableColumnHeader name="affiliate-id" sort={sort}>
              <span>Affiliate ID</span>
            </SortableColumnHeader>

            <SortableColumnHeader name="affiliate-name" sort={sort}>
              <span>Affiliate Name</span>
            </SortableColumnHeader>

            <SortableColumnHeader name="deals" sort={sort}>
              <span>Deals</span>
            </SortableColumnHeader>
          </tr>
        </thead>

        <tbody className={css.tableBody}>
          {sortedLinks.map(link => {
            return (
              <tr key={link.id}>
                <td>{link.content}</td>
                <td>{link.affiliateId}</td>
                <td>{usersById.get(link.affiliateId)?.name || ''}</td>
                <td className={css.noPadding}>
                  {link.assignedDeals.length ? (
                    link.assignedDeals.map(deal => {
                      return (
                        <div key={deal.dealId} className={css.deal}>
                          {deal.dealName
                            ? `${deal.dealName} (${deal.dealId})`
                            : deal.dealId}
                          , starts{' '}
                          {deal.startDate
                            ? format(deal.startDate, 'dd/MM/yyyy')
                            : '?'}
                        </div>
                      );
                    })
                  ) : (
                    <div className={cx(css.deal, css.inactive)}>
                      No active deals
                    </div>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </HTMLTable>
    </div>
  );
});

LinksList.displayName = 'LinksList';
