import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {
  Button,
  Colors,
  InputGroup,
  NumericInput,
  Radio,
  RadioGroup,
} from '@blueprintjs/core';

import {
  AffiliateDeal,
  AssignedLink,
} from '../../../proto/deeplay/jackpoker_exchequer/v1/jackpoker_exchequer';
import {useExchequerClient} from '../../../client/useExchequerClient';
import {DateInput, DateRange, DateRangeInput} from '@blueprintjs/datetime';
import {isEmpty, last, random} from 'lodash';
import {areIntervalsOverlapping, format, parse} from 'date-fns';
import {stylesheet, classes as cx} from 'typestyle';
import {rootLogger} from '../../../logger';
import {
  constructDeal,
  ErrorState,
  PartialTerm,
  TermErrorsState,
  termsToPartialTermsMap,
} from './utils';

const css = stylesheet({
  params: {
    minHeight: 'min-content',
    maxHeight: 'calc(100vh - 50px)',
    overflowY: 'scroll',
    paddingRight: 10,
    marginLeft: 15,
  },
  topHeader: {
    display: 'grid',
    gridTemplateColumns: '80px repeat(2, 40px)',
    justifyContent: 'center',
    alignItems: 'center',
    $nest: {
      div: {
        textAlign: 'end',
      },
    },
  },
  mainSection: {
    display: 'grid',
    gridTemplateColumns: '130px 1fr ',
    gap: 10,
    marginRight: 40,
  },
  fill: {
    gridColumn: `1 / span 2`,
  },
  nameInput: {
    width: 166,
  },
  errorMessage: {
    background: Colors.RED5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
    marginTop: 20,
  },
  smallHeader: {
    textAlign: 'center',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    height: 30,
    marginTop: 30,
  },
  buttons: {
    height: 30,
    marginTop: 30,
    display: 'flex',
    justifyContent: 'space-around',
  },
  deleteTerms: {
    gridRow: 'span 6',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  terms: {
    marginBottom: 10,
    paddingTop: 30,
    gap: 10,
    borderTop: `1px solid ${Colors.TURQUOISE3}`,
    display: 'grid',
    gridTemplateColumns: '130px 1fr 40px',
  },
  addNewTermButton: {
    display: 'flex',
    justifyContent: 'center',
  },
  linksWrapper: {
    display: 'grid',
    gridTemplateColumns: '130px 1fr',
    paddingRight: 40,
    paddingTop: 20,
    borderTop: `1px solid ${Colors.ROSE3}`,
    gap: 10,
  },
  invalidDateInput: {
    $nest: {
      input: {
        // Same as bp3-intent-danger
        boxShadow:
          '0 0 0 0 rgb(219 55 55 / 0%), 0 0 0 0 rgb(219 55 55 / 0%), inset 0 0 0 1px #db3737, inset 0 0 0 1px rgb(16 22 26 / 15%), inset 0 1px 1px rgb(16 22 26 / 20%)',
      },
    },
  },
});

type DealSettingsProps = {
  deal?: AffiliateDeal;
  onCreateDealClick: () => void;
  affiliateId: string;
  onDealPostUpdate: (deal: AffiliateDeal) => void;
  onDealPostDelete: () => void;
  onHistoryToggle: () => void;
};

type DealEditorsProps = {
  deal?: AffiliateDeal;
  affiliateId: string;
  termsById: Map<number, PartialTerm>;
  onCreateDealClick: () => void;
  action: 'create' | 'update';
  link?: AssignedLink;
  onDealPostUpdate: (deal: AffiliateDeal) => void; // Для переключения компонента с create на edit
  onDealPostDelete: () => void;
  onHistoryToggle: () => void;
};

export const DealSettings: React.FC<DealSettingsProps> = memo(({...props}) => {
  const termsById = useMemo(() => {
    if (!props.deal) {
      return new Map();
    }
    return new Map(termsToPartialTermsMap(props.deal.terms));
  }, [props.deal]);
  const link = props.deal ? last(props.deal.links) : undefined;

  return (
    <DealEditor
      action={props.deal ? 'update' : 'create'}
      link={link}
      termsById={termsById}
      {...props}
    />
  );
});

export const DealEditor: React.FC<DealEditorsProps> = memo(
  ({
    deal,
    onCreateDealClick,
    termsById,
    action,
    affiliateId,
    link,
    onDealPostUpdate,
    onDealPostDelete,
    onHistoryToggle,
  }) => {
    const exchequerClient = useExchequerClient();

    const [dealName, setDealName] = useState<string>(deal?.name || '');

    useEffect(() => {
      setDealName(deal?.name || '');
    }, [deal]);

    const handleDealNameChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setErrorMessage(null);
        setDealName(event.target.value);
      },
      [],
    );

    const [errorMessage, setErrorMessage] = useState<ErrorState | null>(null);

    // TODO: добавить поддержку нескольких линков, пока учитываем только последний/единственный
    const [linkDate, setLinkDate] = useState<Date | null>(
      link?.startDate || null,
    );
    const [linkContent, setLinkContent] = useState(link?.content || '');

    useEffect(() => {
      setLinkDate(link?.startDate || null);
      setLinkContent(link?.content || '');
    }, [link]);

    const setNewLinkDate = useCallback((newDate: Date) => {
      setErrorMessage(null);
      setLinkDate(newDate);
    }, []);

    const handleLinkContentChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setErrorMessage(null);
        setLinkContent(event.target.value);
      },
      [],
    );

    const [termsMap, setTermsMap] = useState<Map<number, PartialTerm>>(
      termsById,
    );

    // Update stored value when user clicks on a different deal or deal terms receive an update
    useEffect(() => {
      setTermsMap(termsById);
      setErrorMessage(null);
    }, [termsById]);

    const handleTermsChange = useCallback(
      (termId: number, partialTerm: PartialTerm) => {
        setTermsMap(oldMap => {
          const terms = oldMap.get(termId);
          if (!terms) {
            rootLogger.warn(`Could not find term with id ${termId}`);
            return oldMap;
          }

          const newMap = new Map(oldMap);

          newMap.set(termId, {
            startDate: terms.startDate,
            endDate: terms.endDate,
            ...partialTerm,
          });
          return newMap;
        });
      },
      [],
    );

    const handleTermDelete = useCallback((termId: number) => {
      setErrorMessage(null);
      setTermsMap(oldMap => {
        const newMap = new Map(oldMap);
        newMap.delete(termId);
        return newMap;
      });
    }, []);

    const handlePaymentAmountChange = useCallback(
      (valueAsString: string, termId: number) => {
        setErrorMessage(null);
        setTermsMap(oldMap => {
          const terms = oldMap.get(termId);
          if (!terms) {
            rootLogger.warn(`Could not find term with id ${termId}`);
            return oldMap;
          }

          if (terms.deal.type !== 'cpa') {
            rootLogger.warn(
              {terms},
              'Can not change payment amount on a deal with non CPA type',
            );
            return oldMap;
          }

          const newMap = new Map(oldMap);
          const newTerms: PartialTerm = {
            ...terms,
            deal: {
              ...terms.deal,
              value: {...terms.deal.value, paymentAmount: valueAsString},
            },
          };
          newMap.set(termId, newTerms);

          return newMap;
        });
      },
      [],
    );

    const handleTargetWagerChange = useCallback(
      (valueAsString: string, termId: number) => {
        setErrorMessage(null);

        setTermsMap(oldMap => {
          const terms = oldMap.get(termId);
          if (!terms) {
            rootLogger.warn(`Could not find term with id ${termId}`);
            return oldMap;
          }

          if (terms.deal.type !== 'cpa') {
            rootLogger.warn(
              {terms},
              'Can not change target wager on a deal with non CPA type',
            );
            return oldMap;
          }

          const newMap = new Map(oldMap);
          const newTerms: PartialTerm = {
            ...terms,
            deal: {
              ...terms.deal,
              value: {...terms.deal.value, targetWager: valueAsString},
            },
          };
          newMap.set(termId, newTerms);

          return newMap;
        });
      },
      [],
    );

    const handleMinDepositChange = useCallback(
      (valueAsString: string, termId: number) => {
        setErrorMessage(null);
        setTermsMap(oldMap => {
          const terms = oldMap.get(termId);
          if (!terms) {
            rootLogger.warn(`Could not find term with id ${termId}`);
            return oldMap;
          }

          if (terms.deal.type !== 'cpa') {
            rootLogger.warn(
              {terms},
              'Can not change minimal deposit on a deal with non CPA type',
            );
            return oldMap;
          }

          const newMap = new Map(oldMap);
          const newTerms: PartialTerm = {
            ...terms,
            deal: {
              ...terms.deal,
              value: {...terms.deal.value, minDeposit: valueAsString},
            },
          };
          newMap.set(termId, newTerms);

          return newMap;
        });
      },
      [],
    );

    const handleDealPeriodChange = useCallback(
      (dateRange: DateRange, termId: number) => {
        setErrorMessage(null);

        setTermsMap(oldMap => {
          const terms = oldMap.get(termId);
          if (!terms) {
            rootLogger.warn(`Could not find term with id ${termId}`);
            return oldMap;
          }
          const newMap = new Map(oldMap);

          const newTerms: PartialTerm = {
            ...terms,
            startDate: dateRange[0] || undefined,
            endDate: dateRange[1] || undefined,
          };
          newMap.set(termId, newTerms);

          return newMap;
        });
      },
      [],
    );

    const dateFnsFormat = 'dd/MM/yyyy';
    const formatDate = useCallback(
      (date: Date) => format(date, dateFnsFormat),
      [],
    );
    const parseDate = useCallback((str: string) => {
      const today = new Date();
      return parse(str, dateFnsFormat, today);
    }, []);

    const handleDealPercentageChange = useCallback(
      (valueAsString: string, termId: number) => {
        setErrorMessage(null);
        setTermsMap(oldMap => {
          const terms = oldMap.get(termId);
          if (!terms) {
            rootLogger.warn(`Could not find term with id ${termId}`);
            return oldMap;
          }

          if (terms.deal.type === 'cpa') {
            rootLogger.warn(
              {terms},
              'Can not change deal percentage on a deal with CPA type',
            );
            return oldMap;
          }

          const newMap = new Map(oldMap);
          const newTerms: PartialTerm = {
            ...terms,
            deal: {
              ...terms.deal,
              value: {...terms.deal.value, dealPercentage: valueAsString},
            },
          };
          newMap.set(termId, newTerms);

          return newMap;
        });
      },
      [],
    );

    const handleAddNewTerms = useCallback(() => {
      const id = random(1, 1_000_000_000);
      setTermsMap(oldMap => {
        const newMap = new Map(oldMap);
        newMap.set(id, {deal: {type: 'cpa', value: {}}});
        return newMap;
      });
    }, []);

    const handleDealDelete = useCallback(() => {
      if (action === 'create' || !deal) {
        return;
      }

      const deleteDeal = async () => {
        await exchequerClient.saveAffiliateDeal({
          action: {
            $case: 'delete',
            delete: {dealId: deal.id},
          },
        });
        onDealPostDelete();
      };
      rootLogger.info({deal, link}, 'Deleting deal');
      Promise.resolve()
        .then(() => deleteDeal())
        .catch(err => rootLogger.warn({err}, 'Failed to delete deal'));
    }, [action, deal, exchequerClient, link, onDealPostDelete]);

    const handleDealTypeChange = useCallback(
      (event: React.FormEvent<HTMLInputElement>, termId: number) => {
        setErrorMessage(null);
        const originalValue = termsById.get(termId);

        if (event.currentTarget.value === 'cpa') {
          handleTermsChange(termId, {
            deal: {
              type: 'cpa',
              value:
                originalValue && originalValue.deal.type === 'cpa'
                  ? originalValue.deal.value
                  : {},
            },
          });
        } else if (event.currentTarget.value === 'revenueShareRakeBased') {
          handleTermsChange(termId, {
            deal: {
              type: 'revenueShareRakeBased',
              value:
                originalValue && originalValue.deal.type !== 'cpa'
                  ? originalValue.deal.value
                  : {},
            },
          });
        } else if (
          event.currentTarget.value === 'revenueShareDepositCashoutBased'
        ) {
          handleTermsChange(termId, {
            deal: {
              type: 'revenueShareDepositCashoutBased',
              value:
                originalValue && originalValue.deal.type !== 'cpa'
                  ? originalValue.deal.value
                  : {},
            },
          });
        } else if (event.currentTarget.value === 'revenueShareWinLossBased') {
          handleTermsChange(termId, {
            deal: {
              type: 'revenueShareWinLossBased',
              value:
                originalValue && originalValue.deal.type !== 'cpa'
                  ? originalValue.deal.value
                  : {},
            },
          });
        } else if (
          event.currentTarget.value === 'revenueShareLossWinRakeBased'
        ) {
          handleTermsChange(termId, {
            deal: {
              type: 'revenueShareLossWinRakeBased',
              value:
                originalValue && originalValue.deal.type !== 'cpa'
                  ? originalValue.deal.value
                  : {},
            },
          });
        } else {
          return;
        }
      },
      [handleTermsChange, termsById],
    );

    const checkInputsValidity = useCallback(() => {
      const newErrorState: ErrorState = {};

      if (!dealName) {
        newErrorState.dealName = true;
      }

      if (!linkContent) {
        newErrorState.linkContent = true;
      }

      if (!linkDate) {
        newErrorState.linkDate = true;
        newErrorState.message = 'Link start date can not be empty.';
      }

      const intervalsByTermId: Map<number, Interval> = new Map();

      for (const [id, term] of termsMap.entries()) {
        const termErrorsState: TermErrorsState = {};

        if (!term.endDate) {
          termErrorsState.endDate = true;
        }
        if (!term.startDate) {
          termErrorsState.startDate = true;
        }

        // Собираем периоды сделок, чтобы позже проверить пересечения
        if (term.startDate && term.endDate) {
          intervalsByTermId.set(id, {
            start: term.startDate,
            end: term.endDate,
          });
        }

        if (term.deal.type === 'cpa') {
          if (!term.deal.value.minDeposit) {
            termErrorsState.minDeposit = true;
          }
          if (!term.deal.value.paymentAmount) {
            termErrorsState.paymentAmount = true;
          }
          if (!term.deal.value.targetWager) {
            termErrorsState.targetWager = true;
          }
        } else if (!term.deal.value.dealPercentage) {
          termErrorsState.dealPercentage = true;
        }

        if (!isEmpty(termErrorsState)) {
          newErrorState.termsById
            ? newErrorState.termsById.set(id, termErrorsState)
            : (newErrorState.termsById = new Map([[id, termErrorsState]]));
        }
      }

      if (intervalsByTermId.size > 1) {
        for (const [id, interval] of intervalsByTermId.entries()) {
          const hasOverlap = [...intervalsByTermId.entries()].find(
            ([intervalId, value]) =>
              id !== intervalId &&
              areIntervalsOverlapping(interval, value, {inclusive: true}),
          );

          if (hasOverlap) {
            const termsById =
              newErrorState.termsById || new Map<number, TermErrorsState>();
            const existingTermErrors = termsById.get(id);
            termsById.set(
              id,
              existingTermErrors
                ? {...existingTermErrors, startDate: true, endDate: true}
                : {startDate: true, endDate: true},
            );
            newErrorState.termsById = termsById;
            newErrorState.message =
              'Terms can not have overlapping time periods.';
          }
        }
      }

      if (isEmpty(newErrorState)) {
        return null;
      }

      return newErrorState;
    }, [dealName, linkContent, linkDate, termsMap]);

    const handleDealUpdate = useCallback(() => {
      const saveNewDeal = async () => {
        const newErrorState = checkInputsValidity();
        setErrorMessage(newErrorState);

        if (!dealName || !linkContent || !linkDate || newErrorState) {
          rootLogger.info('New deal can not be saved.');
          return;
        }

        const newDealVersion = constructDeal({
          linkDate,
          linkContent,
          dealName,
          partialTerms: termsMap,
          id: deal?.id || `${Date.now()}`,
          affiliateId,
          linkId: link?.id || `${Date.now()}`,
        });

        rootLogger.info({newDealVersion, deal}, 'Starting update deal request');
        if (newDealVersion === null) {
          return;
        }

        if (action === 'update') {
          await exchequerClient.saveAffiliateDeal({
            action: {$case: 'update', update: {deal: newDealVersion}},
          });
        } else {
          await exchequerClient.saveAffiliateDeal({
            action: {$case: 'create', create: {deal: newDealVersion}},
          });
        }
        return newDealVersion;
      };

      Promise.resolve()
        .then(() => saveNewDeal())
        .then(deal => {
          if (deal) {
            onDealPostUpdate(deal);
          }
        })
        .catch(err =>
          rootLogger.warn(err, 'Caught an error while saving new deal'),
        );
    }, [
      action,
      affiliateId,
      checkInputsValidity,
      deal,
      dealName,
      exchequerClient,
      onDealPostUpdate,
      link?.id,
      linkContent,
      linkDate,
      termsMap,
    ]);

    return (
      <div className={css.params}>
        <h4 className={cx(css.fill, css.topHeader)}>
          {action === 'create' ? <div>Create Deal</div> : <div>Edit Deal</div>}
          <Button
            icon="new-object"
            minimal
            onClick={onCreateDealClick}
            disabled={action === 'create'}
          />
          <Button
            icon="history"
            minimal
            disabled={action === 'create'}
            onClick={onHistoryToggle}
          />
        </h4>
        <div className={css.mainSection}>
          <h5 className={cx(css.fill, css.smallHeader)}>Deal Settings</h5>
          <div>Affiliate ID</div>
          <div>{affiliateId}</div>
          <div>Deal Name</div>
          <InputGroup
            value={dealName}
            onChange={handleDealNameChange}
            className={css.nameInput}
            fill
            intent={errorMessage?.dealName ? 'danger' : 'none'}
          />

          <h5 className={cx(css.fill, css.smallHeader)}>Terms</h5>
        </div>

        {[...termsMap.entries()].map(([id, term]) => {
          const dateRange: DateRange = [
            term.startDate || null,
            term.endDate || null,
          ];
          const errorState =
            errorMessage && errorMessage.termsById
              ? errorMessage.termsById.get(id)
              : undefined;
          return (
            <div key={id} className={cx(css.terms, css.fill)}>
              <div>Deal time range</div>
              <DateRangeInput
                value={dateRange}
                onChange={dateRange => handleDealPeriodChange(dateRange, id)}
                parseDate={parseDate}
                formatDate={formatDate}
                shortcuts={false}
                className={cx(
                  (errorState?.endDate || errorState?.startDate) &&
                    css.invalidDateInput,
                )}
              />
              <div className={css.deleteTerms}>
                <Button icon="trash" onClick={() => handleTermDelete(id)} />
              </div>
              <div>Deal Type</div>
              <RadioGroup
                onChange={event => handleDealTypeChange(event, id)}
                selectedValue={term.deal.type}
              >
                <Radio label="CPA" value="cpa" />
                <Radio
                  label="Revenue Share (Rake Based)"
                  value="revenueShareRakeBased"
                />
                <Radio
                  label="Revenue Share (Deposit/Cashout Based)"
                  value="revenueShareDepositCashoutBased"
                />
                <Radio
                  label="Revenue Share (Win/Loss Based)"
                  value="revenueShareWinLossBased"
                />
                <Radio
                  label="Revenue Share (Loss/Win/Rake Based)"
                  value="revenueShareLossWinRakeBased"
                />
              </RadioGroup>

              {term.deal.type === 'cpa' && (
                <>
                  <div>Payment Amount</div>
                  <NumericInput
                    buttonPosition="none"
                    value={term.deal.value.paymentAmount || undefined}
                    onValueChange={(valueAsNumber, valueAsString) =>
                      handlePaymentAmountChange(valueAsString, id)
                    }
                    fill
                    min={0}
                    intent={errorState?.paymentAmount ? 'danger' : 'none'}
                  />
                  <div>Target Wager</div>
                  <NumericInput
                    buttonPosition="none"
                    value={term.deal.value.targetWager || undefined}
                    onValueChange={(valueAsNumber, valueAsString) =>
                      handleTargetWagerChange(valueAsString, id)
                    }
                    fill
                    min={0}
                    intent={errorState?.targetWager ? 'danger' : 'none'}
                  />
                  <div>Minimal Deposit</div>
                  <NumericInput
                    buttonPosition="none"
                    value={term.deal.value.minDeposit || undefined}
                    onValueChange={(valueAsNumber, valueAsString) =>
                      handleMinDepositChange(valueAsString, id)
                    }
                    fill
                    min={0}
                    intent={errorState?.minDeposit ? 'danger' : 'none'}
                  />
                </>
              )}
              {term.deal.type !== 'cpa' && (
                <>
                  <div>Deal Percentage</div>
                  <NumericInput
                    buttonPosition="none"
                    value={term.deal.value.dealPercentage || undefined}
                    fill
                    onValueChange={(valueAsNumber, valueAsString) =>
                      handleDealPercentageChange(valueAsString, id)
                    }
                    min={0}
                    intent={errorState?.dealPercentage ? 'danger' : 'none'}
                  />
                </>
              )}
            </div>
          );
        })}
        <div className={css.addNewTermButton}>
          <Button onClick={handleAddNewTerms} text="Add new terms" />
        </div>

        <h5 className={css.smallHeader}>Link Settings</h5>
        <div className={css.linksWrapper}>
          <div>Start Date </div>
          <DateInput
            value={linkDate}
            onChange={setNewLinkDate}
            parseDate={parseDate}
            formatDate={formatDate}
            fill
            className={cx(errorMessage?.linkDate && css.invalidDateInput)}
          />
          <div>Link</div>
          <InputGroup
            intent={
              errorMessage && errorMessage.linkContent ? 'danger' : 'none'
            }
            fill
            value={linkContent}
            onChange={handleLinkContentChange}
            className={css.nameInput}
          />
        </div>
        {errorMessage && (
          <div className={cx(css.fill, css.errorMessage)}>
            {errorMessage.message || 'Some values are invalid'}
          </div>
        )}
        {action === 'update' ? (
          <div className={cx(css.fill, css.buttons)}>
            <Button
              disabled={!!errorMessage || !termsMap.size || !dealName}
              onClick={handleDealUpdate}
              text="Save changes"
              intent="success"
              minimal
            />
            <Button
              text="Delete deal"
              intent="danger"
              minimal
              onClick={handleDealDelete}
            />
          </div>
        ) : (
          <div className={cx(css.fill, css.button)}>
            {' '}
            <Button
              disabled={!!errorMessage || !termsMap.size || !dealName}
              onClick={handleDealUpdate}
              text="Save changes"
              intent="success"
              minimal
            />
          </div>
        )}
      </div>
    );
  },
);

DealSettings.displayName = 'DealSettings';
DealEditor.displayName = 'DealEditor';
