import React, {createContext, useContext, useMemo, useState} from 'react';

export type LoadingStatisticsContext = {
  isLoading: boolean;
  setIsLoading: (currentValue: boolean) => void;
  errorMessage: string | null;
  setErrorMessage: (message: string | null) => void;
};

const loadingStatisticsContext = createContext<LoadingStatisticsContext | null>(
  null,
);
loadingStatisticsContext.displayName = 'LoadingStatisticsContext';

export function useLoadingStatisticsContext() {
  const loadingStatistics = useContext(loadingStatisticsContext);

  if (loadingStatistics == null) {
    throw new Error('Missing LoadingStatisticsContextProvider');
  }
  return loadingStatistics;
}

export const LoadingStatisticsContextProvider: React.FC = ({children}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const value = useMemo(
    () => ({
      isLoading,
      setIsLoading,
      errorMessage,
      setErrorMessage,
    }),
    [errorMessage, isLoading],
  );

  return (
    <loadingStatisticsContext.Provider value={value}>
      {children}
    </loadingStatisticsContext.Provider>
  );
};
