import {AppAuthError, Requestor} from '@openid/appauth';
import assert from 'assert';

/**
 * FetchRequestor with support for AbortSignal
 *
 * https://github.com/openid/AppAuth-JS/blob/c30f85e490ab41c9f1e8f8ee05bfdfe964e08626/src/xhr.ts?_pjax=%23js-repo-pjax-container%2C%20div%5Bitemtype%3D%22http%3A%2F%2Fschema.org%2FSoftwareSourceCode%22%5D%20main%2C%20%5Bdata-pjax-container%5D#L48
 */
export class FetchRequestor extends Requestor {
  constructor(private signal: AbortSignal) {
    super();
  }

  async xhr<T>(settings: JQueryAjaxSettings): Promise<T> {
    if (!settings.url) {
      return Promise.reject(new AppAuthError('A URL must be provided.'));
    }

    const url: URL = new URL(settings.url);
    const requestInit: RequestInit = {
      signal: this.signal,
      method: settings.method,
      mode: 'cors',
    };

    if (settings.data) {
      if (settings.method && settings.method.toUpperCase() === 'POST') {
        assert(typeof settings.data === 'string');
        requestInit.body = settings.data;
      } else {
        const searchParams = new URLSearchParams(settings.data);
        searchParams.forEach((value, key) => {
          url.searchParams.append(key, value);
        });
      }
    }

    // Set the request headers
    requestInit.headers = {};
    if (settings.headers) {
      for (const key in settings.headers) {
        if (settings.headers.hasOwnProperty(key)) {
          const value = settings.headers[key];

          if (value != null) {
            requestInit.headers[key] = value;
          }
        }
      }
    }

    const isJsonDataType =
      settings.dataType && settings.dataType.toLowerCase() === 'json';

    // Set 'Accept' header value for json requests (Taken from
    // https://github.com/jquery/jquery/blob/e0d941156900a6bff7c098c8ea7290528e468cf8/src/ajax.js#L644
    // )
    if (isJsonDataType) {
      requestInit.headers['Accept'] =
        'application/json, text/javascript, */*; q=0.01';
    }

    const response = await fetch(url.toString(), requestInit);

    if (response.status >= 200 && response.status < 300) {
      const contentType = response.headers.get('content-type');
      if (
        isJsonDataType ||
        (contentType && contentType.indexOf('application/json') !== -1)
      ) {
        return await response.json();
      } else {
        return (await response.text()) as any;
      }
    } else {
      throw new AppAuthError(response.status.toString(), response.statusText);
    }
  }
}
