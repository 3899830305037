import {
  AppAuthError,
  AuthorizationNotifier,
  AuthorizationRequest,
  AuthorizationRequestResponse,
  AuthorizationServiceConfiguration,
  BaseTokenRequestHandler,
  GRANT_TYPE_AUTHORIZATION_CODE,
  GRANT_TYPE_REFRESH_TOKEN,
  RedirectRequestHandler,
  TokenRequest,
  TokenResponse,
} from '@openid/appauth';
import {delay, retry} from 'abort-controller-x';
import {decodeJwt, JWTPayload} from 'jose';
import {isEqual} from 'lodash-es';
import {
  BehaviorSubject,
  distinctUntilChanged,
  map,
  Observable,
  shareReplay,
} from 'rxjs';
import {FetchRequestor} from './fetchRequestor';

export type OidcClientParams = {
  autoLogin?: boolean;
  issuerUrl: string;
  clientId: string;
  scopes: string[];
};

export type OidcClient = {
  state$: Observable<OidcClientState>;
  /**
   * Will throw if state is not 'logged-out'.
   */
  login(): void;
  /**
   * Will throw if state is not 'logged-in'.
   */
  logout(): void;
  /**
   * Will throw if state is not 'logged-in'.
   */
  getAccessToken(): string;
  run(signal: AbortSignal): Promise<void>;
};

export type OidcClientState =
  | {type: 'initializing'}
  | {type: 'logged-out'}
  | {
      type: 'logged-in';
      idTokenClaims: JWTPayload;
      accessTokenClaims: JWTPayload;
    };

export function OidcClient({
  autoLogin,
  issuerUrl,
  clientId,
  scopes,
}: OidcClientParams): OidcClient {
  type State =
    | {isInitialized: false}
    | ({
        isInitialized: true;
        configuration: AuthorizationServiceConfiguration;
      } & LoginState);

  type LoginState =
    | {
        isLoggedIn: false;
      }
    | {
        isLoggedIn: true;
        tokenResponse: TokenResponse;
      };

  const stateSubject = new BehaviorSubject<State>({isInitialized: false});

  const authorizationHandler = new RedirectRequestHandler();

  const sessionStoreKey = 'oidcState';

  stateSubject.subscribe(state => {
    if (state.isInitialized) {
      saveSessionState(state.isLoggedIn ? state.tokenResponse : undefined);
    }
  });

  const client: OidcClient = {
    state$: stateSubject.pipe(
      map(
        (state): OidcClientState =>
          state.isInitialized
            ? state.isLoggedIn
              ? {
                  type: 'logged-in',
                  idTokenClaims: decodeJwt(state.tokenResponse.idToken!),
                  accessTokenClaims: decodeJwt(state.tokenResponse.accessToken),
                }
              : {type: 'logged-out'}
            : {type: 'initializing'},
      ),
      distinctUntilChanged(isEqual),
      shareReplay({bufferSize: 1, refCount: true}),
    ),

    login(): void {
      const state = stateSubject.getValue();

      if (!state.isInitialized) {
        throw new Error('OidcClient not yet initialized');
      }

      if (state.isLoggedIn) {
        throw new Error('OidcClient already logged in');
      }

      authorizationHandler.performAuthorizationRequest(
        state.configuration,
        new AuthorizationRequest({
          client_id: clientId,
          redirect_uri: window.location.origin,
          scope: ['openid', ...scopes].join(' '),
          response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
          extras: {
            // access_type: 'online',
            response_mode: 'fragment', // use url hash instead of query params
          },
        }),
      );
    },

    logout() {
      const state = stateSubject.getValue();

      if (!state.isInitialized) {
        throw new Error('OidcClient not yet initialized');
      }

      if (!state.isLoggedIn) {
        throw new Error('OidcClient already logged out');
      }

      if (state.isLoggedIn) {
        stateSubject.next({
          isInitialized: state.isInitialized,
          configuration: state.configuration,
          isLoggedIn: false,
        });
      }
    },

    getAccessToken(): string {
      const state = stateSubject.getValue();

      if (!state.isInitialized) {
        throw new Error('OidcClient not yet initialized');
      }

      if (!state.isLoggedIn) {
        throw new Error('OidcClient not logged in');
      }

      return state.tokenResponse.accessToken;
    },

    async run(signal: AbortSignal): Promise<void> {
      const configuration = await retry(signal, signal =>
        AuthorizationServiceConfiguration.fetchFromIssuer(
          issuerUrl,
          new FetchRequestor(signal),
        ),
      );

      let initialTokenResponse: TokenResponse | undefined = loadSessionState();

      if (initialTokenResponse != null && initialTokenResponse.isValid(-10)) {
        stateSubject.next({
          isInitialized: true,
          configuration,
          isLoggedIn: true,
          tokenResponse: initialTokenResponse,
        });
      } else {
        const authorizationNotifier = new AuthorizationNotifier();

        let authorizationResult: AuthorizationRequestResponse | undefined;

        authorizationNotifier.setAuthorizationListener(
          (request, response, error) => {
            authorizationResult = {request, response, error};
          },
        );

        authorizationHandler.setAuthorizationNotifier(authorizationNotifier);

        await authorizationHandler.completeAuthorizationRequestIfPossible();

        if (authorizationResult?.error != null) {
          throw authorizationResult.error;
        }

        if (authorizationResult?.response == null) {
          stateSubject.next({
            isInitialized: true,
            isLoggedIn: false,
            configuration,
          });

          if (autoLogin) {
            client.login();
          }
        } else {
          // remove hash
          window.history.replaceState(
            '',
            document.title,
            window.location.pathname + window.location.search,
          );

          const codeVerifier =
            authorizationResult.request.internal?.code_verifier;

          // this request is not retryable
          initialTokenResponse = await new BaseTokenRequestHandler(
            new FetchRequestor(signal),
          ).performTokenRequest(
            configuration,
            new TokenRequest({
              client_id: clientId,
              redirect_uri: window.location.origin,
              grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
              code: authorizationResult.response.code,
              refresh_token: undefined,
              extras: codeVerifier != null ? {code_verifier: codeVerifier} : {},
            }),
          );

          stateSubject.next({
            isInitialized: true,
            configuration,
            isLoggedIn: true,
            tokenResponse: initialTokenResponse,
          });
        }
      }

      if (initialTokenResponse == null) {
        return;
      }

      let tokenResponse: TokenResponse = initialTokenResponse;

      while (true) {
        const {issuedAt, expiresIn, refreshToken} = tokenResponse;

        if (expiresIn == null || refreshToken == null) {
          return;
        }

        await delay(
          signal,
          new Date((issuedAt + Math.floor(expiresIn * 0.5)) * 1000),
        );

        tokenResponse = await retry(
          signal,
          signal =>
            new BaseTokenRequestHandler(
              new FetchRequestor(signal),
            ).performTokenRequest(
              configuration,
              new TokenRequest({
                client_id: clientId,
                redirect_uri: window.location.origin,
                grant_type: GRANT_TYPE_REFRESH_TOKEN,
                refresh_token: refreshToken,
                extras:
                  tokenResponse.scope != null
                    ? {scope: tokenResponse.scope}
                    : undefined,
              }),
            ),
          {
            onError(err) {
              if (err instanceof AppAuthError) {
                throw err;
              }
            },
          },
        );

        stateSubject.next({
          isInitialized: true,
          configuration,
          isLoggedIn: true,
          tokenResponse,
        });
      }
    },
  };

  return client;

  function loadSessionState(): TokenResponse | undefined {
    const value = window.sessionStorage.getItem(sessionStoreKey);

    if (value == null) {
      return undefined;
    }

    return new TokenResponse(JSON.parse(value));
  }

  function saveSessionState(tokenResponse: TokenResponse | undefined): void {
    if (tokenResponse != null) {
      window.sessionStorage.setItem(
        sessionStoreKey,
        JSON.stringify(tokenResponse.toJson()),
      );
    } else {
      window.sessionStorage.removeItem(sessionStoreKey);
    }
  }
}
