import {UserStatistics} from '../../proto/deeplay/jackpoker_exchequer/v1/jackpoker_exchequer';

export const getColumnDefinitions = (
  columnName: keyof UserStatistics,
): {title: string; description: JSX.Element} => {
  switch (columnName) {
    case 'cashResult':
      return {
        title: 'Cash Result',
        description: (
          <>
            <p>Результат, достигнутый игроком на кэш-столах за период.</p>
            <p>
              Result (cash) - Рассчитывается как сумма результатов всех
              столосессий, окончание которых приходится на указанный период.
            </p>
            <p>
              Столосессия - Группа рук, отыгранная игроком за одним столом с
              момента посадки до момента выхода. Ситаут не прерывает
              столосессию.
            </p>
            <p>
              Соответствует показателю в БО: 'User's Report - Wins (Tables),
              Loss (Tables)'
            </p>
          </>
        ),
      };

    case 'cashRake':
      return {
        title: 'Cash Rake',
        description: (
          <>
            <p>
              Общая сумма начисленного рейка с кеш-столов аффилированного игрока
              за выбранный период.
            </p>
            <p>
              Рассчитывается как сумма начисленного рейка со всех столосессий,
              отыгранных на кеш-столах, чье окончание приходится на указанный
              период.
            </p>

            <p>
              Начисленный рейк - Сумма рейка, взымаемая с банка,
              распределеляемая между игроками в пропорции, соотвественно размеру
              взносов в банк.
            </p>

            <p>
              Столосессия - Группа рук, отыгранная игроком за одним столом с
              момента посадки до момента выхода. Ситаут не прерывает
              столосессию.
            </p>

            <p>Соответствует показателю в БО: 'User's Report - Rake'</p>
          </>
        ),
      };

    case 'cashHands':
      return {
        title: 'Cash Hands',
        description: (
          <>
            <p>
              Общее количество отыгранных игроком раздач на кеш-столах за
              период.
            </p>

            <p>
              Рассчитывается как сумма отыгранных игроком раздач всех
              столосессий, окончание которых приходится на указанный период.
            </p>

            <p>
              Столосессия - Группа рук, отыгранная игроком за одним столом с
              момента посадки до момента выхода. Ситаут не прерывает
              столосессию.
            </p>
            <p>
              Соответствует показателю в БО: 'User's Report - Wins (Tables),
              Loss (Tables)'
            </p>
          </>
        ),
      };
    case 'tournamentResult':
      return {
        title: 'Tournament Result',
        description: (
          <>
            <p>Результат, достигнутый игроком в турнирах за период.</p>

            <p>
              Рассчитывается как сумма выигрышей игрока в турнирах за минусом
              всех инвестиций, вложенных в турниры (энтри/бай-ины, ребаи,
              аддоны),включая рейк.
            </p>

            <p>
              Соответствует показателю в БО: 'User's Report - Wins
              (Tournaments), Loss (Tournaments)'
            </p>
          </>
        ),
      };
    case 'tournamentFee': {
      return {
        title: 'Tournament Investment',
        description: (
          <>
            <p>
              Tournament Investment - Сумма инвестиций в турнир, сделанных
              игроком, в частности сумма энтри/бай-инов, ребаев и адонов, за
              период.
            </p>
            <p>
              Рассчитывается как сумма всех выплат (энтри/бай-ины, ребаи,
              аддоны), включая рейк, произведенных афиллированных игроком в
              турнирах на конец указанного периода.
            </p>
          </>
        ),
      };
    }
    case 'tournamentRake': {
      return {
        title: 'Tournament Rake',
        description: (
          <>
            <p>
              Сумма комиссий, выплаченных игроком в турнирах за выбранный
              период, которые уходят организатору игры.
            </p>
            <p>
              Rake (tournaments) - Рассчитывается как сумма всех комиссий с
              энтри/бай-инов, ребаев и аддонов, выплаченных аффилированным
              игроком на конец указанного периода.
            </p>
            <p>
              Соответствует показателю в БО: 'User's Report - Tournaments Fee'
            </p>
          </>
        ),
      };
    }
    case 'tournamentHands':
      return {
        title: 'Tournament Hands',
        description: (
          <>
            <p>
              Общее количество отыгранных игроком раздач в турнирах за период.
            </p>

            <p>
              Рассчитывается как сумма раздач, отыгранных аффилированным игроком
              в турнирах, которые завершились в указанный период.
            </p>
          </>
        ),
      };
    case 'deposits':
      return {
        title: 'Deposits',
        description: (
          <>
            <p>Сумма всех введенных игроком средств за период.</p>

            <p>
              Рассчитывается как сумма внесенных аффилированным игроком
              депозитов, транзакции которых успешно завершились в указанный
              период.
            </p>
          </>
        ),
      };
    case 'cashouts':
      return {
        title: 'Cashouts',
        description: (
          <>
            <p>Сумма всех выведенных игроком средств за период.</p>

            <p>
              Рассчитывается как сумма выведенных аффилированным игроком
              средств, транзакции которых успешно завершились в указанный
              период.
            </p>
          </>
        ),
      };
    case 'profit':
      return {
        title: 'Profit',
        description: (
          <>
            <p>
              Общий результат, достигнутый игроком за кэш-столами и в турнирах.
            </p>

            <p>
              Рассчитывается как сумма результатов всех отыгранных игроком
              столосессий за кеш-столами, окончание которых приходится на
              указанный период, и результатов турниров, завершившихся в
              указанный период.
            </p>

            <p>
              Столосессия - Группа рук, отыгранная игроком за одним столом с
              момента посадки до момента выхода. Ситаут не прерывает
              столосессию.
            </p>

            <p>Формула рассчета: Result (cash) + Result (tournament)</p>

            <p>Соответствует показателю в БО: 'User's Report - Wins, Loss'</p>
          </>
        ),
      };

    default:
      return {title: '', description: <></>};
  }
};
