import {useCallback, useState} from 'react';

export type TableSort<ColumnName extends string> = {
  column: ColumnName;
  order: 'asc' | 'desc';
  onColumnClick(column: ColumnName): void;
};

export function useTableSort<ColumnName extends string>(
  defaultColumn: ColumnName,
): TableSort<ColumnName> {
  const [{column, order}, setSort] = useState<{
    column: ColumnName;
    order: 'asc' | 'desc';
  }>(() => ({column: defaultColumn, order: 'asc'}));

  const onColumnClick = useCallback((column: ColumnName) => {
    setSort(sort => ({
      column,
      order: sort.column !== column || sort.order === 'desc' ? 'asc' : 'desc',
    }));
  }, []);

  return {
    column,
    order,
    onColumnClick,
  };
}
