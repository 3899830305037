import {Colors, Icon, NonIdealState, Spinner} from '@blueprintjs/core';
import React, {Suspense} from 'react';
import {stylesheet} from 'typestyle';
import {AffiliatesPageWithProviders as AffiliatesPage} from './routes/AffiliatesPage';
import {useOidcClientState} from './auth/useOidcClientState';
import {NavBar} from './NavBar';
import {NavLink, Route, Routes} from 'react-router-dom';
import {DealsPage} from './routes/DealsPage';
import {LinksPage} from './routes/LinksPage';

const styles = stylesheet({
  root: {
    height: '100vh',
  },
  nonIdealState: {
    height: '100vh',
  },
  links: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, max-content)',
    gap: 20,
  },
});

export const App: React.FC = () => {
  const oidcState = useOidcClientState();

  return (
    <div className={styles.root}>
      {oidcState.type === 'initializing' ? (
        <NonIdealState
          className={styles.nonIdealState}
          icon={<Spinner />}
          title="Logging in..."
        />
      ) : (
        <Suspense
          fallback={
            <NonIdealState
              className={styles.nonIdealState}
              icon={<Spinner />}
              title="Loading..."
            />
          }
        >
          {oidcState.type === 'logged-out' ? (
            <NavBar />
          ) : (
            <>
              <NavBar>
                <div className={styles.links}>
                  <NavLink
                    to="/"
                    style={({isActive}) =>
                      isActive ? {color: Colors.BLUE3} : {color: Colors.BLACK}
                    }
                  >
                    <div>
                      <Icon icon="people" /> Users and Affiliates{' '}
                    </div>
                  </NavLink>
                  <NavLink
                    to="/deals"
                    style={({isActive}) =>
                      isActive ? {color: Colors.BLUE3} : {color: Colors.BLACK}
                    }
                  >
                    <div>
                      <Icon icon="credit-card" /> Deals{' '}
                    </div>
                  </NavLink>
                  <NavLink
                    to="/links"
                    style={({isActive}) =>
                      isActive ? {color: Colors.BLUE3} : {color: Colors.BLACK}
                    }
                  >
                    <div>
                      <Icon icon="link" /> Links{' '}
                    </div>
                  </NavLink>
                </div>
              </NavBar>
              <Routes>
                <Route path="/" element={<AffiliatesPage />} />
                <Route path="deals" element={<DealsPage />} />
                <Route path="*" element={<AffiliatesPage />} />
                <Route path="links" element={<LinksPage />} />
              </Routes>
            </>
          )}
        </Suspense>
      )}
    </div>
  );
};
