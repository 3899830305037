import {useMemo} from 'use-memo-one';
import {useOidcClient} from '../auth/useOidcClient';
import {ExchequerClient} from './client';
import {exchequerClientContext} from './context';

export type ExchequerClientProviderProps = {};

export const ExchequerClientProvider: React.FC<ExchequerClientProviderProps> = ({
  children,
}) => {
  const oidcClient = useOidcClient();

  const exchequerClient = useMemo(() => ExchequerClient({oidcClient}), [
    oidcClient,
  ]);

  return (
    <exchequerClientContext.Provider value={exchequerClient}>
      {children}
    </exchequerClientContext.Provider>
  );
};
