import {DateRangeSelect} from './DateRangeSelect';
import {useLoadingStatisticsContext} from './LoadingStatisticsContext';
import {Button, Colors, Spinner} from '@blueprintjs/core';
import {stylesheet} from 'typestyle';
import {useExportUserStatisticsContext} from './ExportUserStatisticsContext';

const css = stylesheet({
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: 450,
  },
  loadingInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 200,
    paddingLeft: 10,
  },
  error: {
    color: Colors.RED3,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 10,
  },
});

export const ToolBar: React.FC = () => {
  const {isLoading, errorMessage} = useLoadingStatisticsContext();
  const {isDisabled, handleDownload} = useExportUserStatisticsContext();

  return (
    <div className={css.toolbar}>
      <Button
        minimal={true}
        small={true}
        disabled={isDisabled}
        onClick={handleDownload}
        text="Export to csv"
      />
      <DateRangeSelect />
      {isLoading && (
        <div className={css.loadingInfo}>
          <span>Loading user statistics... </span> <Spinner size={30} />
        </div>
      )}
      {errorMessage && <div className={css.error}>{errorMessage}</div>}
    </div>
  );
};
