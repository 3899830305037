import {random, sortBy} from 'lodash';
import {Term} from '../../../proto/deeplay/jackpoker_exchequer/v1/deal';
import {
  AffiliateDeal,
  AssignedLink,
} from '../../../proto/deeplay/jackpoker_exchequer/v1/jackpoker_exchequer';
import {fromProtoMoney, toProtoMoney} from '../../../utils/moneyMapper';

type CPA = {minDeposit?: string; targetWager?: string; paymentAmount?: string};
type RevShare = {dealPercentage?: string};

export type PartialTerm = {
  startDate?: Date;
  endDate?: Date;
  deal:
    | {type: 'cpa'; value: CPA}
    | {type: 'revenueShareDepositCashoutBased'; value: RevShare}
    | {type: 'revenueShareRakeBased'; value: RevShare}
    | {type: 'revenueShareWinLossBased'; value: RevShare}
    | {type: 'revenueShareLossWinRakeBased'; value: RevShare};
};

export const termsToPartialTermsMap = (
  terms: Term[],
): Map<number, PartialTerm> => {
  const termsMap: Map<number, PartialTerm> = new Map();

  for (const term of terms) {
    const id = random(0, 1_000_000_000);

    const partialTerm: PartialTerm = {
      startDate: term.startDate,
      endDate: term.endDate,
      deal: {type: 'cpa', value: {}},
    };

    if (term.type?.$case === 'cpa') {
      partialTerm.deal = {
        type: 'cpa',
        value: {
          minDeposit: fromProtoMoney(term.type.cpa.minimalDeposit).toString(),
          targetWager: fromProtoMoney(term.type.cpa.targetWager).toString(),
          paymentAmount: fromProtoMoney(term.type.cpa.paymentAmount).toString(),
        },
      };

      termsMap.set(id, partialTerm);
      continue;
    }

    if (term.type?.$case === 'revenueShareDepositCashoutBased') {
      partialTerm.deal = {
        type: 'revenueShareDepositCashoutBased',
        value: {
          dealPercentage: (
            (term.type.revenueShareDepositCashoutBased.dealPercentage /
              1_000_000_000) *
            100
          ).toString(),
        },
      };

      termsMap.set(id, partialTerm);
      continue;
    }

    if (term.type?.$case === 'revenueShareRakeBased') {
      partialTerm.deal = {
        type: 'revenueShareRakeBased',
        value: {
          dealPercentage: (
            (term.type.revenueShareRakeBased.dealPercentage / 1_000_000_000) *
            100
          ).toString(),
        },
      };

      termsMap.set(id, partialTerm);
      continue;
    }

    if (term.type?.$case === 'revenueShareWinLossBased') {
      partialTerm.deal = {
        type: 'revenueShareWinLossBased',
        value: {
          dealPercentage: (
            (term.type.revenueShareWinLossBased.dealPercentage /
              1_000_000_000) *
            100
          ).toString(),
        },
      };

      termsMap.set(id, partialTerm);
      continue;
    }
    if (term.type?.$case === 'revenueShareLossWinRakeBased') {
      partialTerm.deal = {
        type: 'revenueShareLossWinRakeBased',
        value: {
          dealPercentage: (
            (term.type.revenueShareLossWinRakeBased.dealPercentage /
              1_000_000_000) *
            100
          ).toString(),
        },
      };

      termsMap.set(id, partialTerm);
      continue;
    }
  }

  return termsMap;
};

export type TermErrorsState = {
  dealName?: true;
  dealPercentage?: true;
  paymentAmount?: true;
  targetWager?: true;
  minDeposit?: true;
  startDate?: true;
  endDate?: true;
};
export type ErrorState = {
  dealName?: true;
  linkDate?: true;
  linkContent?: true;
  termsById?: Map<number, TermErrorsState>;
  message?: string;
};

type ConstructDealParams = {
  linkDate: Date;
  linkContent: string;
  dealName: string;
  partialTerms: Map<number, PartialTerm>;
  id: string;
  affiliateId: string;
  linkId: string;
};

export const constructDeal = ({
  linkDate,
  linkContent,
  dealName,
  partialTerms,
  id,
  affiliateId,
  linkId,
}: ConstructDealParams): AffiliateDeal | null => {
  let newDeal: AffiliateDeal = AffiliateDeal.fromPartial({
    id,
    affiliateId,
    name: dealName,
  });

  const terms: Term[] = [];

  for (const partialTerm of partialTerms.values()) {
    if (!partialTerm.endDate || !partialTerm.startDate) {
      return null;
    }

    if (partialTerm.deal.type === 'cpa') {
      if (
        !partialTerm.deal.value.minDeposit ||
        !partialTerm.deal.value.paymentAmount ||
        !partialTerm.deal.value.targetWager
      ) {
        return null;
      } else {
        const minDeposit = Number(partialTerm.deal.value.minDeposit);
        const paymentAmount = Number(partialTerm.deal.value.paymentAmount);
        const targetWager = Number(partialTerm.deal.value.targetWager);

        if (isNaN(minDeposit) || isNaN(paymentAmount) || isNaN(targetWager)) {
          return null;
        } else {
          terms.push(
            Term.fromPartial({
              startDate: partialTerm.startDate,
              endDate: partialTerm.endDate,
              type: {
                $case: 'cpa',
                cpa: {
                  minimalDeposit: toProtoMoney(minDeposit),
                  targetWager: toProtoMoney(targetWager),
                  paymentAmount: toProtoMoney(paymentAmount),
                },
              },
            }),
          );
        }
      }
    } else {
      if (!partialTerm.deal.value.dealPercentage) {
        return null;
      }

      if (partialTerm.deal.type === 'revenueShareDepositCashoutBased') {
        const dealPercentage = Number(partialTerm.deal.value.dealPercentage);
        if (isNaN(dealPercentage) || dealPercentage === 0) {
          return null;
        }

        terms.push(
          Term.fromPartial({
            startDate: partialTerm.startDate,
            endDate: partialTerm.endDate,
            type: {
              $case: 'revenueShareDepositCashoutBased',
              revenueShareDepositCashoutBased: {
                dealPercentage: (1_000_000_000 / 100) * dealPercentage,
              },
            },
          }),
        );
      }

      if (partialTerm.deal.type === 'revenueShareRakeBased') {
        const dealPercentage = Number(partialTerm.deal.value.dealPercentage);
        if (isNaN(dealPercentage) || dealPercentage === 0) {
          return null;
        }

        terms.push(
          Term.fromPartial({
            startDate: partialTerm.startDate,
            endDate: partialTerm.endDate,
            type: {
              $case: 'revenueShareRakeBased',
              revenueShareRakeBased: {
                dealPercentage: (1_000_000_000 / 100) * dealPercentage,
              },
            },
          }),
        );
      }

      if (partialTerm.deal.type === 'revenueShareWinLossBased') {
        const dealPercentage = Number(partialTerm.deal.value.dealPercentage);
        if (isNaN(dealPercentage) || dealPercentage === 0) {
          return null;
        }

        terms.push(
          Term.fromPartial({
            startDate: partialTerm.startDate,
            endDate: partialTerm.endDate,
            type: {
              $case: 'revenueShareWinLossBased',
              revenueShareWinLossBased: {
                dealPercentage: (1_000_000_000 / 100) * dealPercentage,
              },
            },
          }),
        );
      }

      if (partialTerm.deal.type === 'revenueShareLossWinRakeBased') {
        const dealPercentage = Number(partialTerm.deal.value.dealPercentage);
        if (isNaN(dealPercentage) || dealPercentage === 0) {
          return null;
        }

        terms.push(
          Term.fromPartial({
            startDate: partialTerm.startDate,
            endDate: partialTerm.endDate,
            type: {
              $case: 'revenueShareLossWinRakeBased',
              revenueShareLossWinRakeBased: {
                dealPercentage: (1_000_000_000 / 100) * dealPercentage,
              },
            },
          }),
        );
      }
    }
  }
  newDeal = {
    ...newDeal,
    terms: sortBy(terms, term => term.endDate),
    links: [
      AssignedLink.fromPartial({
        startDate: linkDate,
        id: linkId,
        content: linkContent,
      }),
    ],
  };

  return newDeal;
};
