import React, {useState, useCallback, useMemo, memo, useEffect} from 'react';

import {Colors, HTMLTable, Icon} from '@blueprintjs/core';
import {stylesheet} from 'typestyle';

import {important} from 'csx';
import {useTableSort} from '../../hooks/useTableSort';
import {ColumnHeaderWithSearch} from '../../components/ColumnHeaderWithSearch';
import {SortableColumnHeader} from '../../components/SortableColumnHeader';
import {invoke} from '../../utils/invoke';
import {isEqual, ListIteratee, orderBy} from 'lodash';
import assertNever from 'assert-never';
import {useDateRangeSelectContext} from './DateRangeSelect';
import {UserStatistics} from '../../proto/deeplay/jackpoker_exchequer/v1/jackpoker_exchequer';
import {useExchequerClient} from '../../client/useExchequerClient';
import {run} from 'abort-controller-x';
import {rootLogger} from '../../logger';
import {useLoadingStatisticsContext} from './LoadingStatisticsContext';
import {ColumnHeaderWithTooltip} from '../../components/ColumnHeaderWithTooltip';
import {getColumnDefinitions} from './columnDefinitions';
import {DateRange} from '@blueprintjs/datetime';
import {format} from 'date-fns';
import {useExportUserStatisticsContext} from './ExportUserStatisticsContext';
import {ExpandedUser} from '../../subscriptions/usersObservableContext';

const css = stylesheet({
  tableWrapper: {
    height: '100%',
    overflowY: 'scroll',
    overflowX: 'scroll',
  },
  tableHead: {
    position: 'sticky',
    top: 0,
    backgroundColor: Colors.WHITE,
    boxShadow: '0 1px 0 0 rgb(16 22 26 / 15%)',
    $nest: {
      '& tr th': {
        textAlign: 'start',
      },
    },
  },

  table: {
    width: '100%',
    tableLayout: 'fixed',
  },

  tableBody: {
    $nest: {
      '& tr:first-child td': {
        boxShadow: important('none'),
      },
    },
  },
  tableFooter: {
    $nest: {
      '& tr td': {
        borderTop: `1px solid ${Colors.DARK_GRAY1}`,
      },
      '& tr th': {
        borderTop: `1px solid ${Colors.DARK_GRAY1}`,
      },
    },
  },
  searchHeader: {
    display: 'flex',
    padding: important('5px'),
    alignItems: 'center',
  },
  searchIcon: {
    paddingRight: 5,
  },
});

export type ExpandedStatistics = UserStatistics & {affiliateId: string};

type SortColumn = 'user-name' | 'user-id';
type Search = {
  column: SortColumn;
  text: string;
} | null;

export type UsersListProps = {
  usersById: Map<string, ExpandedUser>;
  selectedAffiliateId: string | null;
};
export type ConvertedUserStatistics = {
  id: string;
  cashResult: number;
  cashRake: number;
  cashHands: number;
  tournamentResult: number;
  tournamentFee: number;
  tournamentHands: number;
  tournamentRake: number;
  deposits: number;
  cashouts: number;
  profit: number;
};

export type ConvertedUserStatisticsWithName = ConvertedUserStatistics & {
  userName: string;
};

/**
 * Users List - это список пользователей, которые относятся к какому-то агенту.
 */
export const UsersList: React.FC<UsersListProps> = memo(
  ({usersById, selectedAffiliateId}) => {
    const [usersList, setUsersList] = useState<ExpandedUser[]>([]);

    useEffect(() => {
      setUsersList(oldList => {
        if (selectedAffiliateId !== null) {
          const users: ExpandedUser[] = [];
          for (const user of usersById.values()) {
            if (user.agentId === selectedAffiliateId) {
              users.push(user);
            }
          }

          if (!isEqual(users, oldList)) {
            return users;
          }
        }
        return oldList;
      });
    }, [selectedAffiliateId, usersById]);

    const {setIsLoading, setErrorMessage} = useLoadingStatisticsContext();

    const {dateRange, setDateRange} = useDateRangeSelectContext();

    const exchequerClient = useExchequerClient();
    const [userStatisticsById, setUsersStatisticsById] = useState<Map<
      string,
      ConvertedUserStatistics
    > | null>(null);

    // Saving dates from last successful request to avoid rerequesting
    const [
      lastRequestedDateRange,
      setLastRequestedDateRange,
    ] = useState<DateRange>([null, null]);

    useEffect(() => {
      setDateRange([null, null]);
      setUsersStatisticsById(null);
      setLastRequestedDateRange([null, null]);
      // Reset selected dates and loaded statistics when new table opens
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAffiliateId]);

    useEffect(() => {
      const stop = run(async signal => {
        try {
          if (
            dateRange[0] &&
            dateRange[1] &&
            usersList.length &&
            selectedAffiliateId &&
            (!isEqual(dateRange[0], lastRequestedDateRange[0]) ||
              !isEqual(dateRange[1], lastRequestedDateRange[1]))
          ) {
            setErrorMessage(null);
            const startDate = format(dateRange[0], 'yyyy-MM-dd');
            const endDate = format(dateRange[1], 'yyyy-MM-dd');
            rootLogger.info({startDate, endDate}, 'Formatted dates');
            setIsLoading(true);
            const result = await exchequerClient.getUserStatistics(
              {
                startDate,
                endDate,
                userIds: usersList.map(user => user.id),
                affiliateId: selectedAffiliateId,
              },
              {signal},
            );

            setLastRequestedDateRange(dateRange);

            setUsersStatisticsById(() => {
              const newUserStatisticsById = new Map<
                string,
                ConvertedUserStatistics
              >();

              for (const statistics of result.items) {
                newUserStatisticsById.set(statistics.id, {
                  ...statistics,
                  cashResult: convertMoney(statistics.cashResult),
                  cashRake: convertMoney(statistics.cashRake),
                  cashHands: statistics.cashHands.toNumber(),
                  tournamentResult: convertMoney(statistics.tournamentResult),
                  tournamentRake: convertMoney(statistics.tournamentRake),
                  tournamentHands: statistics.tournamentHands.toNumber(),
                  tournamentFee: convertMoney(statistics.tournamentFee),
                  deposits: convertMoney(statistics.deposits),
                  cashouts: convertMoney(statistics.cashouts),
                  profit: convertMoney(statistics.profit),
                });
              }
              return newUserStatisticsById;
            });
          }
        } catch (err) {
          rootLogger.warn({err}, 'Unexpected error.');
          setErrorMessage('Failed to fetch user statistics from ClickHouse');
        } finally {
          setIsLoading(false);
        }
      });

      return () => {
        setIsLoading(false);
        stop();
      };
    }, [
      dateRange,
      exchequerClient,
      lastRequestedDateRange,
      selectedAffiliateId,
      setErrorMessage,
      setIsLoading,
      usersList,
    ]);

    const {setIsDisabled, setData} = useExportUserStatisticsContext();

    const total: ConvertedUserStatisticsWithName | null = useMemo(() => {
      if (userStatisticsById === null) {
        return null;
      } else {
        return {
          id: 'Total',
          userName: `${userStatisticsById.size}`,
          cashResult: getTotal(userStatisticsById, 'cashResult'),
          cashRake: getTotal(userStatisticsById, 'cashRake'),
          cashHands: getTotal(userStatisticsById, 'cashHands'),
          tournamentResult: getTotal(userStatisticsById, 'tournamentResult'),
          tournamentRake: getTotal(userStatisticsById, 'tournamentRake'),
          tournamentHands: getTotal(userStatisticsById, 'tournamentHands'),
          tournamentFee: getTotal(userStatisticsById, 'tournamentFee'),
          deposits: getTotal(userStatisticsById, 'deposits'),
          cashouts: getTotal(userStatisticsById, 'cashouts'),
          profit: getTotal(userStatisticsById, 'profit'),
        };
      }
    }, [userStatisticsById]);

    useEffect(() => {
      if (
        userStatisticsById !== null &&
        selectedAffiliateId !== null &&
        total !== null
      ) {
        setIsDisabled(false);
        setData(
          [...userStatisticsById.values(), total].map(({id, ...stat}) => ({
            id,
            userName: usersList.find(user => user.id === id)?.name || '',
            affiliateId: selectedAffiliateId,
            ...stat,
          })),
        );
      }
    }, [
      usersList,
      selectedAffiliateId,
      setData,
      setIsDisabled,
      total,
      userStatisticsById,
    ]);

    const [search, setSearch] = useState<Search>(null);
    useEffect(() => {
      setSearch(null);
    }, [selectedAffiliateId]);

    const closeSearch = useCallback(() => {
      setSearch(null);
    }, []);

    const setSearchText = useCallback(
      (columnName: SortColumn, text: string) => {
        setSearch({column: columnName, text});
      },
      [],
    );

    const sort = useTableSort<SortColumn>('user-id');

    const sortedUsers = useMemo(() => {
      const iteratee = invoke(
        (): ListIteratee<ExpandedUser> => {
          switch (sort.column) {
            case 'user-name':
              return user => user.name;
            case 'user-id':
              return user => user.id;
            default:
              return assertNever(sort.column);
          }
        },
      );

      if (sort.column === 'user-name') {
        return orderBy(usersList, iteratee, sort.order);
      }

      return orderBy(
        usersList,
        [iteratee, group => group.name],
        [sort.order, 'asc'],
      );
    }, [usersList, sort]);

    const filteredUsers = useMemo(() => {
      return sortedUsers.filter(user => {
        if (userStatisticsById !== null) {
          if (search !== null) {
            if (search.column === 'user-name') {
              return (
                !!userStatisticsById.get(user.id) &&
                user.name.toLowerCase().includes(search.text.toLowerCase())
              );
            }

            if (search.column === 'user-id') {
              return (
                !!userStatisticsById.get(user.id) &&
                user.id.includes(search.text.toLowerCase())
              );
            }
          } else {
            return !!userStatisticsById.get(user.id);
          }
        } else {
          if (search !== null) {
            if (search.column === 'user-name') {
              return user.name
                .toLowerCase()
                .includes(search.text.toLowerCase());
            }

            if (search.column === 'user-id') {
              return user.id.includes(search.text.toLowerCase());
            }
          }
        }

        return true;
      });
    }, [sortedUsers, search, userStatisticsById]);
    const onSearchColumnClick = useCallback(
      (event: React.MouseEvent, columnName: SortColumn) => {
        event.stopPropagation();
        setSearch({column: columnName, text: ''});
      },
      [],
    );

    return (
      <div className={css.tableWrapper}>
        <HTMLTable striped className={css.table}>
          <colgroup>
            <col style={{width: '100px'}}></col>
            <col style={{width: '120px'}}></col>
            <col style={{width: '100px'}}></col>
            <col style={{width: '100px'}}></col>
            <col style={{width: '100px'}}></col>
            <col style={{width: '100px'}}></col>
            <col style={{width: '100px'}}></col>
            <col style={{width: '100px'}}></col>
            <col style={{width: '100px'}}></col>
            <col style={{width: '100px'}}></col>
            <col style={{width: '100px'}}></col>
            <col style={{width: '100px'}}></col>
          </colgroup>

          <thead className={css.tableHead}>
            <tr>
              {search && search.column === 'user-id' ? (
                <ColumnHeaderWithSearch
                  className={css.searchHeader}
                  searchText={search.text}
                  onColumnClick={closeSearch}
                  setSearchText={text => setSearchText('user-id', text)}
                  placeholder="User ID"
                />
              ) : (
                <SortableColumnHeader name="user-id" sort={sort}>
                  <Icon
                    icon="search"
                    onClick={event => onSearchColumnClick(event, 'user-id')}
                    className={css.searchIcon}
                  />
                  <span>User ID</span>
                </SortableColumnHeader>
              )}
              {search && search.column === 'user-name' ? (
                <ColumnHeaderWithSearch
                  className={css.searchHeader}
                  searchText={search.text}
                  onColumnClick={closeSearch}
                  setSearchText={text => setSearchText('user-name', text)}
                  placeholder="User Name"
                />
              ) : (
                <SortableColumnHeader name="user-name" sort={sort}>
                  <Icon
                    icon="search"
                    onClick={event => onSearchColumnClick(event, 'user-name')}
                    className={css.searchIcon}
                  />
                  <span>User Name</span>
                </SortableColumnHeader>
              )}
              <ColumnHeaderWithTooltip
                targetContent="Result (cash)"
                tooltipContent={getColumnDefinitions('cashResult')}
              />
              <ColumnHeaderWithTooltip
                targetContent="Rake (cash)"
                tooltipContent={getColumnDefinitions('cashRake')}
              />
              <ColumnHeaderWithTooltip
                targetContent="Hands (cash)"
                tooltipContent={getColumnDefinitions('cashHands')}
              />
              <ColumnHeaderWithTooltip
                targetContent="Result (tourn)"
                tooltipContent={getColumnDefinitions('tournamentResult')}
              />
              <ColumnHeaderWithTooltip
                targetContent="Rake (tourn)"
                tooltipContent={getColumnDefinitions('tournamentRake')}
              />
              <ColumnHeaderWithTooltip
                targetContent="Hands (tourn)"
                tooltipContent={getColumnDefinitions('tournamentHands')}
              />
              <ColumnHeaderWithTooltip
                targetContent="Investment (tourn)"
                tooltipContent={getColumnDefinitions('tournamentFee')}
              />
              <ColumnHeaderWithTooltip
                targetContent="Deposit"
                tooltipContent={getColumnDefinitions('deposits')}
              />
              <ColumnHeaderWithTooltip
                targetContent="Cashout"
                tooltipContent={getColumnDefinitions('cashouts')}
              />
              <ColumnHeaderWithTooltip
                targetContent="Profit"
                tooltipContent={getColumnDefinitions('profit')}
              />
            </tr>
          </thead>

          <tbody className={css.tableBody}>
            {filteredUsers.map(user => {
              const statistics = userStatisticsById?.get(user.id);

              return (
                <tr key={user.id}>
                  <td>{user.id}</td>
                  <td>{user.name}</td>
                  <td>{statistics ? statistics.cashResult : ''}</td>
                  <td>{statistics ? statistics.cashRake : ''}</td>
                  <td>{statistics ? statistics.cashHands : ''}</td>
                  <td>{statistics ? statistics.tournamentResult : ''}</td>
                  <td>{statistics ? statistics.tournamentRake : ''}</td>
                  <td>{statistics ? statistics.tournamentHands : ''}</td>
                  <td>{statistics ? statistics.tournamentFee : ''}</td>
                  <td>{statistics ? statistics.deposits : ''}</td>
                  <td>{statistics ? statistics.cashouts : ''}</td>
                  <td>{statistics ? statistics.profit : ''}</td>
                </tr>
              );
            })}
          </tbody>
          {total !== null ? (
            <tfoot className={css.tableFooter}>
              <tr>
                <th>Total</th>
                <td> {total.userName} </td>
                <td>{total.cashResult}</td>
                <td>{total.cashRake}</td>
                <td>{total.cashHands}</td>
                <td>{total.tournamentResult}</td>
                <td>{total.tournamentRake}</td>
                <td>{total.tournamentHands}</td>
                <td>{total.tournamentFee}</td>
                <td>{total.deposits}</td>
                <td>{total.cashouts}</td>
                <td>{total.profit}</td>
              </tr>
            </tfoot>
          ) : null}
        </HTMLTable>
      </div>
    );
  },
);

const getTotal = (
  userStatisticsById: Map<string, ConvertedUserStatistics>,
  key: keyof ConvertedUserStatistics,
): number => {
  let total = 0;

  for (const user of userStatisticsById.values()) {
    const value = user[key];
    if (typeof value !== 'string') {
      total += value;
    }
  }
  return total % 1 === 0 ? total : Number(total.toFixed(2));
};

const convertMoney = (money: Long | undefined): number => {
  if (!money) {
    return 0;
  } else {
    const value = money.toNumber();
    return value / 100;
  }
};
