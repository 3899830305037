import {useContext} from 'react';
import {authContext} from './context';

export function useOidcClient() {
  const client = useContext(authContext);

  if (client == null) {
    throw new Error('Missing AuthProvider');
  }

  return client;
}
