import React from 'react';
import {Alignment, Button, Navbar as BlueprintNavBar} from '@blueprintjs/core';

import {useOidcClient} from '../auth/useOidcClient';
import {useOidcClientState} from '../auth/useOidcClientState';

export const NavBar: React.FC = props => {
  const oidcClient = useOidcClient();
  const oidcState = useOidcClientState();

  return (
    <BlueprintNavBar>
      <BlueprintNavBar.Group align={Alignment.LEFT}>
        <BlueprintNavBar.Heading>Exchequer</BlueprintNavBar.Heading>
        {oidcState.type === 'logged-in' && props.children
          ? props.children
          : null}
      </BlueprintNavBar.Group>
      <BlueprintNavBar.Group align={Alignment.RIGHT}>
        <BlueprintNavBar.Divider />
        {oidcState.type === 'logged-in' ? (
          <Button
            icon="log-out"
            minimal={true}
            text="Log out"
            onClick={() => oidcClient.logout()}
          />
        ) : (
          <Button
            icon="log-in"
            minimal={true}
            text="Log in"
            onClick={() => oidcClient.login()}
          />
        )}
      </BlueprintNavBar.Group>
    </BlueprintNavBar>
  );
};
