import React, {memo, useMemo} from 'react';

import {classes as cx, stylesheet} from 'typestyle';
import {important} from 'csx';
import {Colors, HTMLTable} from '@blueprintjs/core';
import {useTableSort} from '../../hooks/useTableSort';
import {first, ListIteratee, orderBy} from 'lodash';
import {invoke} from '../../utils/invoke';
import assertNever from 'assert-never';
import {SortableColumnHeader} from '../../components/SortableColumnHeader';
import {AffiliateDeal} from '../../proto/deeplay/jackpoker_exchequer/v1/jackpoker_exchequer';
import {useUsersObservableContext} from '../../subscriptions/usersObservableContext';
import {useObservableState} from 'observable-hooks';
import {
  INITIAL_STATE,
  UsersContextState,
  ExpandedUser,
} from '../../subscriptions/usersObservableContext';

const css = stylesheet({
  tableWrapper: {
    height: '100%',
    overflowY: 'scroll',
    margin: '0 10px',
    border: `1px solid ${Colors.DARK_GRAY2}`,
  },
  tableHead: {
    position: 'sticky',
    top: 0,
    backgroundColor: Colors.WHITE,
    boxShadow: '0 1px 0 0 rgb(16 22 26 / 15%)',
    $nest: {
      '& tr th': {
        textAlign: 'start',
      },
    },
  },

  table: {
    width: '100%',
    tableLayout: 'fixed',
  },

  tableBody: {
    $nest: {
      '& tr:first-child td': {
        boxShadow: important('none'),
      },
    },
  },
  noPadding: {
    padding: important(0),
  },
  deal: {
    padding: 10,
    $nest: {
      '&:hover': {
        cursor: 'pointer',
        background: Colors.BLUE5,
        opacity: important(1),
      },
    },
  },
  newDeal: {
    opacity: 0,
    $nest: {
      '&:hover': {
        opacity: important(1),
      },
    },
  },
  selectedAffiliate: {
    background: '#48aff04f', // Blue5 with low opacity
  },
  selectedDeal: {
    background: Colors.BLUE5,
  },
});

type SortColumn = 'affiliate-name' | 'affiliate-id' | 'deals';

export type AffiliatesListProps = {
  onDealClick: (dealId: string, affiliateId: string) => void;
  selectedAffiliateId: string | null;
  selectedDealId: string | null;
  dealsByAffiliateId: Map<string, AffiliateDeal[]>;
};

/**
 * Affiliates List - это список пользователей, которые являются чьими-либо агентами.
 */
export const AffiliatesList: React.FC<AffiliatesListProps> = memo(
  ({onDealClick, selectedDealId, dealsByAffiliateId, selectedAffiliateId}) => {
    const observable$ = useUsersObservableContext();
    const [{affiliatesList}] = useObservableState<UsersContextState>(
      () => observable$,
      INITIAL_STATE,
    );

    const sort = useTableSort<SortColumn>('deals');

    const sortedAffiliates = useMemo(() => {
      const iteratee = invoke(
        (): ListIteratee<ExpandedUser> => {
          switch (sort.column) {
            case 'affiliate-name':
              return user => user.name;
            case 'affiliate-id':
              return user => user.id;
            case 'deals':
              return user => {
                const firstDeal = first(dealsByAffiliateId.get(user.id) || []);
                return firstDeal?.name;
              };
            default:
              return assertNever(sort.column);
          }
        },
      );

      return orderBy(affiliatesList, iteratee, sort.order);
    }, [affiliatesList, dealsByAffiliateId, sort.column, sort.order]);

    return (
      <div className={css.tableWrapper}>
        <HTMLTable striped className={css.table}>
          <colgroup>
            <col style={{width: '150px'}}></col>
            <col style={{width: '100px'}}></col>
            <col style={{width: '250px'}}></col>
          </colgroup>

          <thead className={css.tableHead}>
            <tr>
              <SortableColumnHeader name="affiliate-name" sort={sort}>
                <span>Affiliate Name</span>
              </SortableColumnHeader>

              <SortableColumnHeader name="affiliate-id" sort={sort}>
                <span>Affiliate ID</span>
              </SortableColumnHeader>

              <SortableColumnHeader name="deals" sort={sort}>
                <span>Deals</span>
              </SortableColumnHeader>
            </tr>
          </thead>

          <tbody className={css.tableBody}>
            {sortedAffiliates.map(affiliate => {
              const deals = dealsByAffiliateId.get(affiliate.id);
              return (
                <tr
                  key={affiliate.id}
                  className={cx(
                    selectedAffiliateId === affiliate.id &&
                      css.selectedAffiliate,
                  )}
                >
                  <td>{affiliate.name}</td>
                  <td>{affiliate.id}</td>
                  <td className={css.noPadding}>
                    {deals ? (
                      deals.map(deal => (
                        <div
                          key={deal.id}
                          className={cx(
                            deal.id === selectedDealId && css.selectedDeal,
                            css.deal,
                          )}
                          onClick={() => onDealClick(deal.id, affiliate.id)}
                        >
                          {deal.name} ({deal.id})
                        </div>
                      ))
                    ) : (
                      <div
                        className={cx(css.deal, css.newDeal)}
                        onClick={() => onDealClick('no-deal', affiliate.id)}
                      >
                        Create Deal
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </HTMLTable>
      </div>
    );
  },
);

AffiliatesList.displayName = 'AffiliatesList';
