import {catchAbortError} from 'abort-controller-x';
import {useEffect, useState} from 'react';
import {authContext} from './context';
import {OidcClient} from './OidcClient';

export type AuthProviderProps = {
  client: OidcClient;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({
  client: oidcClient,
  children,
}) => {
  const [error, setError] = useState<{err: unknown}>();

  if (error != null) {
    throw error.err;
  }

  useEffect(() => {
    const abortController = new AbortController();

    oidcClient
      .run(abortController.signal)
      .catch(catchAbortError)
      .catch(err => {
        setError({err});
      });

    return () => {
      abortController.abort();
    };
  }, [oidcClient]);

  return (
    <authContext.Provider value={oidcClient}>{children}</authContext.Provider>
  );
};

AuthProvider.displayName = 'AuthProvider';
