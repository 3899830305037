import {FocusStyleManager, NonIdealState, Spinner} from '@blueprintjs/core';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import 'normalize.css/normalize.css';
import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {App} from './App';
import {ErrorBoundary} from 'react-error-boundary';
import {OidcClient} from './auth/OidcClient';
import {appConfig} from './config';
import {AuthProvider} from './auth/AuthProvider';
import {ExchequerClientProvider} from './client/ExchequerClientProvider';
import {LoadingStatisticsContextProvider} from './routes/AffiliatesPage/LoadingStatisticsContext';
import {BrowserRouter as Router} from 'react-router-dom';
import {UsersObservableContextProvider} from './subscriptions/usersObservableContext';

FocusStyleManager.onlyShowFocusOnTabs();

const oidcClient = OidcClient({
  ...appConfig.oidc,
  autoLogin: true,
});

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary
      fallback={<NonIdealState icon="error" title="Unknown error happened" />}
      onError={(err, info) => {
        console.error(`Uncaught error\n${info.componentStack}\n`, err);
      }}
    >
      <Suspense
        fallback={<NonIdealState icon={<Spinner />} title="Loading..." />}
      >
        <AuthProvider client={oidcClient}>
          <ExchequerClientProvider>
            <LoadingStatisticsContextProvider>
              <UsersObservableContextProvider>
                <Router>
                  <App />
                </Router>
              </UsersObservableContextProvider>
            </LoadingStatisticsContextProvider>
          </ExchequerClientProvider>
        </AuthProvider>
      </Suspense>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);
