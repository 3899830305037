import {useObservable} from '@ngneat/react-rxjs';
import {OidcClientState} from './OidcClient';
import {useOidcClient} from './useOidcClient';

export function useOidcClientState(): OidcClientState {
  const oidcClient = useOidcClient();

  const [value, {error, completed}] = useObservable(oidcClient.state$);

  if (error) {
    throw error;
  }

  if (completed) {
    throw new Error('Observable completed');
  }

  if (value == null) {
    throw new Error('Observable did not emit synchronously');
  }

  return value;
}
