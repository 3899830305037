import {run} from 'abort-controller-x';
import {useCallback, useState, useEffect, useMemo} from 'react';
import {stylesheet, classes as cx} from 'typestyle';
import {useExchequerClient} from '../../client/useExchequerClient';
import {AffiliateDeal} from '../../proto/deeplay/jackpoker_exchequer/v1/jackpoker_exchequer';
import {AffiliatesList} from './AffiliatesList';
import {subscribeToDeals} from '../../subscriptions/dealsSubscription';
import {filter, isEqual, last} from 'lodash';
import {DealSettings} from './DealSettings';
import {DealAndLinkVersionHistory} from './VersionHistory';

const css = stylesheet({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    minWidth: 1600,
    gap: 50,
    height: 'calc(100vh - 50px)',
  },
});
type DealsPageProps = {
  className?: string;
};

export const DealsPage: React.FC<DealsPageProps> = ({className}) => {
  const [selectedAffiliateId, setSelectedAffiliateId] = useState<string | null>(
    null,
  );

  const exchequerClient = useExchequerClient();
  const [dealsByAffiliateId, setDealsByAffiliateId] = useState<
    Map<string, AffiliateDeal[]>
  >(new Map());

  const [selectedDealId, setSelectedDealId] = useState<string | null>(null);
  const [selectedDeal, setSelectedDeal] = useState<AffiliateDeal | null>(null);

  const [isHistoryOpen, setIsHistoryOpen] = useState(false);

  const handleDealClick = useCallback(
    (dealId: string, affiliateId: string) => {
      setSelectedDealId(dealId);
      setSelectedAffiliateId(affiliateId);

      if (dealId === 'no-deal') {
        setSelectedDeal(null);
        return;
      }

      const deals = dealsByAffiliateId.get(affiliateId);

      if (deals) {
        const selectedDeal = deals.find(deal => deal.id === dealId);
        if (selectedDeal) {
          setSelectedDeal(selectedDeal);
        }
      }
    },
    [dealsByAffiliateId],
  );
  // FIXME: переделать, когда добавится поддержка нескольких линков у сделки
  const lastLinkId = useMemo(() => {
    if (selectedDeal) {
      return last(selectedDeal.links)?.id;
    } else {
      return undefined;
    }
  }, [selectedDeal]);

  const handleDealPostUpdate = useCallback((deal: AffiliateDeal) => {
    setSelectedDealId(deal.id);
    setSelectedDeal(deal);
  }, []);

  const handleDealPostDelete = useCallback(() => {
    setSelectedAffiliateId(null);
    setSelectedDeal(null);
    setSelectedDealId(null);
  }, []);

  const handleDealCreateClick = useCallback(() => {
    setSelectedDeal(null);
    setSelectedDealId('no-deal');
  }, []);

  const handleToggleHistory = useCallback(() => {
    setIsHistoryOpen(isOpen => !isOpen);
  }, []);

  useEffect(() => {
    const stop = run(async signal => {
      for await (const updates of subscribeToDeals(signal, exchequerClient)) {
        setDealsByAffiliateId(deals => {
          const newDeals: Map<string, AffiliateDeal[]> = new Map(deals);
          for (const {key, value} of updates) {
            if (value != null) {
              const existingDeals = newDeals.get(value.affiliateId);

              newDeals.set(
                value.affiliateId,
                existingDeals
                  ? [
                      ...filter(existingDeals, deal => deal.id !== value.id),
                      value,
                    ]
                  : [value],
              );
            } else {
              const dealsWithAffiliateId = [
                ...newDeals.entries(),
              ].find(([key, deals]) => deals.some(deal => deal.id === key));
              if (dealsWithAffiliateId) {
                newDeals.set(
                  dealsWithAffiliateId[0],
                  dealsWithAffiliateId[1].filter(deal => deal.id !== key),
                );
              }
            }
          }

          if (!isEqual(deals, newDeals)) {
            return newDeals;
          } else {
            return deals;
          }
        });
      }
    });

    return () => {
      stop();
    };
  }, [exchequerClient]);

  return (
    <div className={cx(css.root, className)}>
      <AffiliatesList
        onDealClick={handleDealClick}
        selectedDealId={selectedDealId}
        dealsByAffiliateId={dealsByAffiliateId}
        selectedAffiliateId={selectedAffiliateId}
      />
      {selectedDeal && selectedAffiliateId && (
        <DealSettings
          deal={selectedDeal}
          onCreateDealClick={handleDealCreateClick}
          affiliateId={selectedAffiliateId}
          onDealPostUpdate={handleDealPostUpdate}
          onDealPostDelete={handleDealPostDelete}
          onHistoryToggle={handleToggleHistory}
        />
      )}
      {selectedDealId === 'no-deal' && selectedAffiliateId && (
        <DealSettings
          onCreateDealClick={handleDealCreateClick}
          affiliateId={selectedAffiliateId}
          onDealPostUpdate={handleDealPostUpdate}
          onDealPostDelete={handleDealPostDelete}
          onHistoryToggle={handleToggleHistory}
        />
      )}
      {selectedDealId && selectedDealId !== 'no-deal' && isHistoryOpen && (
        <DealAndLinkVersionHistory
          affiliateId={selectedAffiliateId}
          dealId={selectedDealId}
          lastLinkId={lastLinkId}
        />
      )}
    </div>
  );
};
