import {useContext} from 'react';
import {exchequerClientContext} from './context';

export function useExchequerClient() {
  const client = useContext(exchequerClientContext);

  if (client == null) {
    throw new Error('Missing ExchequerClientProvider');
  }

  return client;
}
