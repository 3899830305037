import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {unparse} from 'papaparse';
import {rootLogger} from '../../logger';
import {useDateRangeSelectContext} from './DateRangeSelect';
import {format} from 'date-fns';
import {ConvertedUserStatisticsWithName} from './UsersList';

export type ExportUserStatisticsContext = {
  isDisabled: boolean;
  setIsDisabled: (newValue: boolean) => void;
  handleDownload: () => void;
  data: null | ConvertedUserStatisticsWithName[];
  setData: (newStatistics: ConvertedUserStatisticsWithName[] | null) => void;
};

const exportUserStatisticsContext = createContext<ExportUserStatisticsContext | null>(
  null,
);
exportUserStatisticsContext.displayName = 'ExportUserStatisticsContext';

export function useExportUserStatisticsContext() {
  const exportUserStatistics = useContext(exportUserStatisticsContext);

  if (exportUserStatistics == null) {
    throw new Error('Missing ExportUserStatisticsContextProvider');
  }
  return exportUserStatistics;
}

export const ExportUserStatisticsContextProvider: React.FC = ({children}) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const {dateRange} = useDateRangeSelectContext();
  const [data, setData] = useState<null | ConvertedUserStatisticsWithName[]>(
    null,
  );

  const handleDownload = useCallback(async () => {
    if (isDisabled || !dateRange[0] || !dateRange[1] || data === null) {
      return;
    }

    try {
      const result = unparse(data);

      const blob = new Blob(['\ufeff' + result], {
        type: 'text/csv;charset=utf-8',
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `affiliated-users[${format(
        dateRange[0],
        'yyyy-MM-dd',
      )}-${format(dateRange[1], 'yyyy-MM-dd')}].csv`;
      a.click();
      URL.revokeObjectURL(url);
    } catch (err) {
      rootLogger.warn({err}, 'Failed to export to csv');
    }
  }, [data, dateRange, isDisabled]);

  const value = useMemo(
    () => ({
      isDisabled,
      setIsDisabled,
      handleDownload,
      data,
      setData,
    }),
    [data, handleDownload, isDisabled],
  );

  return (
    <exportUserStatisticsContext.Provider value={value}>
      {children}
    </exportUserStatisticsContext.Provider>
  );
};
