import {Icon, InputGroup} from '@blueprintjs/core';
import {classes, stylesheet} from 'typestyle';

export type ColumnHeaderWithSearchProps = {
  className?: string;
  searchText: string;
  onColumnClick: () => void;
  setSearchText: (text: string) => void;
  placeholder: string;
};

const css = stylesheet({
  root: {
    cursor: 'pointer',
  },
  crossIcon: {
    marginLeft: 5,
  },
});

export function ColumnHeaderWithSearch({
  className,
  searchText,
  onColumnClick,
  setSearchText,
  placeholder,
}: ColumnHeaderWithSearchProps) {
  return (
    <th className={classes(css.root, className)}>
      <InputGroup
        placeholder={`${placeholder}...`}
        value={searchText}
        onChange={event => setSearchText(event.currentTarget.value)}
      />
      <Icon icon="cross" onClick={onColumnClick} className={css.crossIcon} />
    </th>
  );
}
