import {ClientMiddleware, Metadata} from 'nice-grpc-web';
import {OidcClient} from '../auth/OidcClient';

export type AuthMiddlewareParams = {
  oidcClient: OidcClient;
};

export function AuthMiddleware({
  oidcClient,
}: AuthMiddlewareParams): ClientMiddleware {
  return async function* authMiddleware(call, options) {
    const metadata = Metadata(options.metadata).set(
      'Authorization',
      `Bearer ${oidcClient.getAccessToken()}`,
    );

    return yield* call.next(call.request, {
      ...options,
      metadata,
    });
  };
}
