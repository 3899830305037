import React, {useState, useCallback, useMemo, memo} from 'react';

import {Colors, HTMLTable, Icon} from '@blueprintjs/core';
import {classes as cx, stylesheet} from 'typestyle';

import {important} from 'csx';
import {useTableSort} from '../../hooks/useTableSort';
import {ColumnHeaderWithSearch} from '../../components/ColumnHeaderWithSearch';
import {SortableColumnHeader} from '../../components/SortableColumnHeader';
import {invoke} from '../../utils/invoke';
import {ListIteratee, orderBy} from 'lodash';
import assertNever from 'assert-never';
import {ExpandedUser} from '../../subscriptions/usersObservableContext';

const css = stylesheet({
  tableWrapper: {
    height: '100%',
    overflowY: 'scroll',
    margin: '0 10px',
    border: `1px solid ${Colors.DARK_GRAY2}`,
  },
  tableHead: {
    position: 'sticky',
    top: 0,
    backgroundColor: Colors.WHITE,
    boxShadow: '0 1px 0 0 rgb(16 22 26 / 15%)',
    $nest: {
      '& tr th': {
        textAlign: 'start',
      },
    },
  },

  table: {
    width: '100%',
    tableLayout: 'fixed',
  },

  tableBody: {
    $nest: {
      '& tr:first-child td': {
        boxShadow: important('none'),
      },
    },
  },
  searchHeader: {
    display: 'flex',
    padding: important('5px'),
    alignItems: 'center',
  },
  searchIcon: {
    paddingRight: 5,
  },
  selectedRow: {
    background: Colors.BLUE5,
  },
});
type SortColumn = 'affiliate-name' | 'affiliate-id';
type Search = {
  column: SortColumn;
  text: string;
} | null;

export type AffiliatesListProps = {
  affiliatesList: ExpandedUser[];
  onRowClick: (affiliate: ExpandedUser) => void;
  selectedAffiliateId: string | null;
};
/**
 * Affiliates List - это список пользователей, которые являются чьими-либо агентами.
 */
export const AffiliatesList: React.FC<AffiliatesListProps> = memo(
  ({affiliatesList, onRowClick, selectedAffiliateId}) => {
    const [search, setSearch] = useState<Search>(null);

    const closeSearch = useCallback(() => {
      setSearch(null);
    }, []);

    const setSearchText = useCallback(
      (columnName: SortColumn, text: string) => {
        setSearch({column: columnName, text});
      },
      [],
    );

    const sort = useTableSort<SortColumn>('affiliate-name');

    const sortedAffiliates = useMemo(() => {
      const iteratee = invoke(
        (): ListIteratee<ExpandedUser> => {
          switch (sort.column) {
            case 'affiliate-name':
              return user => user.name;
            case 'affiliate-id':
              return user => user.id;
            default:
              return assertNever(sort.column);
          }
        },
      );

      return orderBy(affiliatesList, iteratee, sort.order);
    }, [affiliatesList, sort]);

    const filteredAffiliates = useMemo(() => {
      return sortedAffiliates.filter(user => {
        if (search !== null) {
          if (search.column === 'affiliate-name') {
            return user.name.toLowerCase().includes(search.text.toLowerCase());
          }

          if (search.column === 'affiliate-id') {
            return user.id.includes(search.text.toLowerCase());
          }
        }

        return true;
      });
    }, [sortedAffiliates, search]);
    const onSearchColumnClick = useCallback(
      (event: React.MouseEvent, columnName: SortColumn) => {
        event.stopPropagation();
        setSearch({column: columnName, text: ''});
      },
      [],
    );

    return (
      <div className={css.tableWrapper}>
        <HTMLTable striped interactive className={css.table}>
          <colgroup>
            <col style={{width: '150px'}}></col>
            <col style={{width: '100px'}}></col>
            <col style={{width: '150px'}}></col>
          </colgroup>

          <thead className={css.tableHead}>
            <tr>
              {search && search.column === 'affiliate-name' ? (
                <ColumnHeaderWithSearch
                  className={css.searchHeader}
                  searchText={search.text}
                  onColumnClick={closeSearch}
                  setSearchText={text => setSearchText('affiliate-name', text)}
                  placeholder="Affiliate Name"
                />
              ) : (
                <SortableColumnHeader name="affiliate-name" sort={sort}>
                  <Icon
                    icon="search"
                    onClick={event =>
                      onSearchColumnClick(event, 'affiliate-name')
                    }
                    className={css.searchIcon}
                  />
                  <span>Affiliate Name</span>
                </SortableColumnHeader>
              )}

              {search && search.column === 'affiliate-id' ? (
                <ColumnHeaderWithSearch
                  className={css.searchHeader}
                  searchText={search.text}
                  onColumnClick={closeSearch}
                  setSearchText={text => setSearchText('affiliate-id', text)}
                  placeholder="Affiliate ID"
                />
              ) : (
                <SortableColumnHeader name="affiliate-id" sort={sort}>
                  <Icon
                    icon="search"
                    onClick={event =>
                      onSearchColumnClick(event, 'affiliate-id')
                    }
                    className={css.searchIcon}
                  />
                  <span>Affiliate ID</span>
                </SortableColumnHeader>
              )}

              <th>Parent Affiliate</th>
            </tr>
          </thead>

          <tbody className={css.tableBody}>
            {filteredAffiliates.map(affiliate => (
              <tr
                key={affiliate.id}
                onClick={() => onRowClick(affiliate)}
                className={cx(
                  affiliate.id === selectedAffiliateId && css.selectedRow,
                )}
              >
                <td>{affiliate.name}</td>
                <td>{affiliate.id}</td>
                <td>{formatAffiliateInfo(affiliate)}</td>
              </tr>
            ))}
          </tbody>
        </HTMLTable>
      </div>
    );
  },
);

const formatAffiliateInfo = (affiliate: ExpandedUser): string => {
  if (!affiliate.affiliateName) {
    return '';
  } else {
    return `${affiliate.affiliateName} (${affiliate.agentId || ''})`;
  }
};
