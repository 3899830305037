/* eslint-disable */
import { messageTypeRegistry } from "../../../typeRegistry";
import { Timestamp } from "../../../google/protobuf/timestamp";
import Long from "long";
import { Money } from "../../common/money";
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "deeplay.jackpoker_exchequer.v1";

/**
 * Сделка - набор договорённостей с агентом, за выполнение условий которых агент
 * получает плату. Сделка действует в течение некоторого временного периода.
 * Аффилиированный игрок учитывается в сделке, если данная сделка привязана
 * к линку (Link) этого игрока.
 */
export interface Deal {
  $type: "deeplay.jackpoker_exchequer.v1.Deal";
  /** Уникальный идентификатор сделки */
  id: string;
  /** Человекочитаемое название сделки */
  name: string;
  /**
   * Отсортированная по времени коллекция условий сделки,
   * которые действовали в определённый период
   */
  terms: Term[];
}

/** Условия сделки, включая тип и период, в который сделка действительна */
export interface Term {
  $type: "deeplay.jackpoker_exchequer.v1.Term";
  startDate: Date | undefined;
  endDate: Date | undefined;
  type?:
    | { $case: "cpa"; cpa: CPA }
    | {
        $case: "revenueShareRakeBased";
        revenueShareRakeBased: RevShareRakeBased;
      }
    | {
        $case: "revenueShareDepositCashoutBased";
        revenueShareDepositCashoutBased: RevShareDepositCashoutBased;
      }
    | {
        $case: "revenueShareWinLossBased";
        revenueShareWinLossBased: RevShareWinLossBased;
      }
    | {
        $case: "revenueShareLossWinRakeBased";
        revenueShareLossWinRakeBased: RevShareLossWinRakeBased;
      };
}

/**
 * Сделка типа CPA - фиксированная плата за приведенного игрока при выполнении
 * им ВСЕХ установленных условий.
 */
export interface CPA {
  $type: "deeplay.jackpoker_exchequer.v1.CPA";
  /** Размер фиксированной выплаты за выполнение условий сделки. */
  paymentAmount: Money | undefined;
  /** Минимальная сумма депозитов игрока, для получения выплаты агенту. */
  minimalDeposit: Money | undefined;
  /**
   * Минимальный размер wager_cash для получения агентом выплаты за игрока,
   * где wager_cash - это сумма денег, которыми рисковал игрок в cash играх, в
   * период действия сделки.
   */
  targetWager: Money | undefined;
}

/**
 * Revenue share - Выплата агенту установленного % с рейка, наигранного
 * привлеченным им игроком. Сделка типа Revenue share (based on rake-costs) -
 * агент получает % по сделке с разницы между уплаченным аффилированным
 * игроком рейком и затратами на поддержание игры этого игрока.
 */
export interface RevShareRakeBased {
  $type: "deeplay.jackpoker_exchequer.v1.RevShareRakeBased";
  /**
   * Процент агента от сделки в нано-единицах.
   * Пример: 94% = 940_000_000, 2.45% = 24_500_000.
   */
  dealPercentage: number;
}

/**
 * Revenue share - Выплата агенту установленного % с рейка, наигранного
 * привлеченным им игроком. Сделка типа Revenue share (based on
 * deposits-cashouts-costs) - агент получает % по сделке с разницы между
 * депозитами игрока и его кешаутами, за минусом отмытых бонусов аффилированного
 * игрока.
 */
export interface RevShareDepositCashoutBased {
  $type: "deeplay.jackpoker_exchequer.v1.RevShareDepositCashoutBased";
  /**
   * Процент агента от сделки в нано-единицах.
   * Пример: 94% = 940_000_000, 2.45% = 24_500_000.
   */
  dealPercentage: number;
}

/**
 * Revenue share - Выплата агенту установленного % с рейка, наигранного
 * привлеченным им игроком. Сделка типа Revenue share (based on win-loss-costs)
 * - агент получает % по сделке с общего результата, достигнутого игроком за
 * кэш-столами и в турнирах, за минусом отмытых бонусов.
 */
export interface RevShareWinLossBased {
  $type: "deeplay.jackpoker_exchequer.v1.RevShareWinLossBased";
  /**
   * Процент агента от сделки в нано-единицах.
   * Пример: 94% = 940_000_000, 2.45% = 24_500_000.
   */
  dealPercentage: number;
}

/**
 * Revenue share - Выплата агенту установленного % с рейка, наигранного
 * привлеченным им игроком. Сделка типа Revenue share (based on
 * loss-win-rake-costs)
 * - агент получает % по сделке с разницы между суммой профита
 * (где профит рассчитывается как loss - win) и начисленным рейком с
 * кэш-столов и в турнирах, за минусом отмытых бонусов.
 */
export interface RevShareLossWinRakeBased {
  $type: "deeplay.jackpoker_exchequer.v1.RevShareLossWinRakeBased";
  /**
   * Процент агента от сделки в нано-единицах.
   * Пример: 94% = 940_000_000, 2.45% = 24_500_000.
   */
  dealPercentage: number;
}

function createBaseDeal(): Deal {
  return {
    $type: "deeplay.jackpoker_exchequer.v1.Deal",
    id: "",
    name: "",
    terms: [],
  };
}

export const Deal = {
  $type: "deeplay.jackpoker_exchequer.v1.Deal" as const,

  encode(message: Deal, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    for (const v of message.terms) {
      Term.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Deal {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeal();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.name = reader.string();
          break;
        case 3:
          message.terms.push(Term.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<Deal>): Deal {
    const message = createBaseDeal();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.terms = object.terms?.map((e) => Term.fromPartial(e)) || [];
    return message;
  },
};

messageTypeRegistry.set(Deal.$type, Deal);

function createBaseTerm(): Term {
  return {
    $type: "deeplay.jackpoker_exchequer.v1.Term",
    startDate: undefined,
    endDate: undefined,
    type: undefined,
  };
}

export const Term = {
  $type: "deeplay.jackpoker_exchequer.v1.Term" as const,

  encode(message: Term, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.startDate !== undefined) {
      Timestamp.encode(
        toTimestamp(message.startDate),
        writer.uint32(10).fork()
      ).ldelim();
    }
    if (message.endDate !== undefined) {
      Timestamp.encode(
        toTimestamp(message.endDate),
        writer.uint32(18).fork()
      ).ldelim();
    }
    if (message.type?.$case === "cpa") {
      CPA.encode(message.type.cpa, writer.uint32(26).fork()).ldelim();
    }
    if (message.type?.$case === "revenueShareRakeBased") {
      RevShareRakeBased.encode(
        message.type.revenueShareRakeBased,
        writer.uint32(34).fork()
      ).ldelim();
    }
    if (message.type?.$case === "revenueShareDepositCashoutBased") {
      RevShareDepositCashoutBased.encode(
        message.type.revenueShareDepositCashoutBased,
        writer.uint32(42).fork()
      ).ldelim();
    }
    if (message.type?.$case === "revenueShareWinLossBased") {
      RevShareWinLossBased.encode(
        message.type.revenueShareWinLossBased,
        writer.uint32(50).fork()
      ).ldelim();
    }
    if (message.type?.$case === "revenueShareLossWinRakeBased") {
      RevShareLossWinRakeBased.encode(
        message.type.revenueShareLossWinRakeBased,
        writer.uint32(58).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Term {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTerm();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.startDate = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          );
          break;
        case 2:
          message.endDate = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          );
          break;
        case 3:
          message.type = {
            $case: "cpa",
            cpa: CPA.decode(reader, reader.uint32()),
          };
          break;
        case 4:
          message.type = {
            $case: "revenueShareRakeBased",
            revenueShareRakeBased: RevShareRakeBased.decode(
              reader,
              reader.uint32()
            ),
          };
          break;
        case 5:
          message.type = {
            $case: "revenueShareDepositCashoutBased",
            revenueShareDepositCashoutBased: RevShareDepositCashoutBased.decode(
              reader,
              reader.uint32()
            ),
          };
          break;
        case 6:
          message.type = {
            $case: "revenueShareWinLossBased",
            revenueShareWinLossBased: RevShareWinLossBased.decode(
              reader,
              reader.uint32()
            ),
          };
          break;
        case 7:
          message.type = {
            $case: "revenueShareLossWinRakeBased",
            revenueShareLossWinRakeBased: RevShareLossWinRakeBased.decode(
              reader,
              reader.uint32()
            ),
          };
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<Term>): Term {
    const message = createBaseTerm();
    message.startDate = object.startDate ?? undefined;
    message.endDate = object.endDate ?? undefined;
    if (
      object.type?.$case === "cpa" &&
      object.type?.cpa !== undefined &&
      object.type?.cpa !== null
    ) {
      message.type = { $case: "cpa", cpa: CPA.fromPartial(object.type.cpa) };
    }
    if (
      object.type?.$case === "revenueShareRakeBased" &&
      object.type?.revenueShareRakeBased !== undefined &&
      object.type?.revenueShareRakeBased !== null
    ) {
      message.type = {
        $case: "revenueShareRakeBased",
        revenueShareRakeBased: RevShareRakeBased.fromPartial(
          object.type.revenueShareRakeBased
        ),
      };
    }
    if (
      object.type?.$case === "revenueShareDepositCashoutBased" &&
      object.type?.revenueShareDepositCashoutBased !== undefined &&
      object.type?.revenueShareDepositCashoutBased !== null
    ) {
      message.type = {
        $case: "revenueShareDepositCashoutBased",
        revenueShareDepositCashoutBased:
          RevShareDepositCashoutBased.fromPartial(
            object.type.revenueShareDepositCashoutBased
          ),
      };
    }
    if (
      object.type?.$case === "revenueShareWinLossBased" &&
      object.type?.revenueShareWinLossBased !== undefined &&
      object.type?.revenueShareWinLossBased !== null
    ) {
      message.type = {
        $case: "revenueShareWinLossBased",
        revenueShareWinLossBased: RevShareWinLossBased.fromPartial(
          object.type.revenueShareWinLossBased
        ),
      };
    }
    if (
      object.type?.$case === "revenueShareLossWinRakeBased" &&
      object.type?.revenueShareLossWinRakeBased !== undefined &&
      object.type?.revenueShareLossWinRakeBased !== null
    ) {
      message.type = {
        $case: "revenueShareLossWinRakeBased",
        revenueShareLossWinRakeBased: RevShareLossWinRakeBased.fromPartial(
          object.type.revenueShareLossWinRakeBased
        ),
      };
    }
    return message;
  },
};

messageTypeRegistry.set(Term.$type, Term);

function createBaseCPA(): CPA {
  return {
    $type: "deeplay.jackpoker_exchequer.v1.CPA",
    paymentAmount: undefined,
    minimalDeposit: undefined,
    targetWager: undefined,
  };
}

export const CPA = {
  $type: "deeplay.jackpoker_exchequer.v1.CPA" as const,

  encode(message: CPA, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.paymentAmount !== undefined) {
      Money.encode(message.paymentAmount, writer.uint32(10).fork()).ldelim();
    }
    if (message.minimalDeposit !== undefined) {
      Money.encode(message.minimalDeposit, writer.uint32(18).fork()).ldelim();
    }
    if (message.targetWager !== undefined) {
      Money.encode(message.targetWager, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CPA {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCPA();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.paymentAmount = Money.decode(reader, reader.uint32());
          break;
        case 2:
          message.minimalDeposit = Money.decode(reader, reader.uint32());
          break;
        case 3:
          message.targetWager = Money.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<CPA>): CPA {
    const message = createBaseCPA();
    message.paymentAmount =
      object.paymentAmount !== undefined && object.paymentAmount !== null
        ? Money.fromPartial(object.paymentAmount)
        : undefined;
    message.minimalDeposit =
      object.minimalDeposit !== undefined && object.minimalDeposit !== null
        ? Money.fromPartial(object.minimalDeposit)
        : undefined;
    message.targetWager =
      object.targetWager !== undefined && object.targetWager !== null
        ? Money.fromPartial(object.targetWager)
        : undefined;
    return message;
  },
};

messageTypeRegistry.set(CPA.$type, CPA);

function createBaseRevShareRakeBased(): RevShareRakeBased {
  return {
    $type: "deeplay.jackpoker_exchequer.v1.RevShareRakeBased",
    dealPercentage: 0,
  };
}

export const RevShareRakeBased = {
  $type: "deeplay.jackpoker_exchequer.v1.RevShareRakeBased" as const,

  encode(
    message: RevShareRakeBased,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.dealPercentage !== 0) {
      writer.uint32(8).uint32(message.dealPercentage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RevShareRakeBased {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRevShareRakeBased();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.dealPercentage = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<RevShareRakeBased>): RevShareRakeBased {
    const message = createBaseRevShareRakeBased();
    message.dealPercentage = object.dealPercentage ?? 0;
    return message;
  },
};

messageTypeRegistry.set(RevShareRakeBased.$type, RevShareRakeBased);

function createBaseRevShareDepositCashoutBased(): RevShareDepositCashoutBased {
  return {
    $type: "deeplay.jackpoker_exchequer.v1.RevShareDepositCashoutBased",
    dealPercentage: 0,
  };
}

export const RevShareDepositCashoutBased = {
  $type: "deeplay.jackpoker_exchequer.v1.RevShareDepositCashoutBased" as const,

  encode(
    message: RevShareDepositCashoutBased,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.dealPercentage !== 0) {
      writer.uint32(8).uint32(message.dealPercentage);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): RevShareDepositCashoutBased {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRevShareDepositCashoutBased();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.dealPercentage = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<RevShareDepositCashoutBased>
  ): RevShareDepositCashoutBased {
    const message = createBaseRevShareDepositCashoutBased();
    message.dealPercentage = object.dealPercentage ?? 0;
    return message;
  },
};

messageTypeRegistry.set(
  RevShareDepositCashoutBased.$type,
  RevShareDepositCashoutBased
);

function createBaseRevShareWinLossBased(): RevShareWinLossBased {
  return {
    $type: "deeplay.jackpoker_exchequer.v1.RevShareWinLossBased",
    dealPercentage: 0,
  };
}

export const RevShareWinLossBased = {
  $type: "deeplay.jackpoker_exchequer.v1.RevShareWinLossBased" as const,

  encode(
    message: RevShareWinLossBased,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.dealPercentage !== 0) {
      writer.uint32(8).uint32(message.dealPercentage);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): RevShareWinLossBased {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRevShareWinLossBased();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.dealPercentage = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<RevShareWinLossBased>): RevShareWinLossBased {
    const message = createBaseRevShareWinLossBased();
    message.dealPercentage = object.dealPercentage ?? 0;
    return message;
  },
};

messageTypeRegistry.set(RevShareWinLossBased.$type, RevShareWinLossBased);

function createBaseRevShareLossWinRakeBased(): RevShareLossWinRakeBased {
  return {
    $type: "deeplay.jackpoker_exchequer.v1.RevShareLossWinRakeBased",
    dealPercentage: 0,
  };
}

export const RevShareLossWinRakeBased = {
  $type: "deeplay.jackpoker_exchequer.v1.RevShareLossWinRakeBased" as const,

  encode(
    message: RevShareLossWinRakeBased,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.dealPercentage !== 0) {
      writer.uint32(8).uint32(message.dealPercentage);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): RevShareLossWinRakeBased {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRevShareLossWinRakeBased();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.dealPercentage = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<RevShareLossWinRakeBased>
  ): RevShareLossWinRakeBased {
    const message = createBaseRevShareLossWinRakeBased();
    message.dealPercentage = object.dealPercentage ?? 0;
    return message;
  },
};

messageTypeRegistry.set(
  RevShareLossWinRakeBased.$type,
  RevShareLossWinRakeBased
);

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Long
  ? string | number | Long
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string }
  ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & {
      $case: T["$case"];
    }
  : T extends {}
  ? { [K in Exclude<keyof T, "$type">]?: DeepPartial<T[K]> }
  : Partial<T>;

function toTimestamp(date: Date): Timestamp {
  const seconds = numberToLong(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { $type: "google.protobuf.Timestamp", seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds.toNumber() * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function numberToLong(number: number) {
  return Long.fromNumber(number);
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
