/* eslint-disable */
import { messageTypeRegistry } from "../../../typeRegistry";
import { CallContext, CallOptions } from "nice-grpc-common";
import { Timestamp } from "../../../google/protobuf/timestamp";
import Long from "long";
import { Term } from "./deal";
import { Link } from "./link";
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "deeplay.jackpoker_exchequer.v1";

export interface User {
  $type: "deeplay.jackpoker_exchequer.v1.User";
  id: string;
  name: string;
  agentId?: string | undefined;
  /** Момент времени, в который данные записаны в локальную базу */
  updatedAt: Date | undefined;
}

export interface ListUsersRequest {
  $type: "deeplay.jackpoker_exchequer.v1.ListUsersRequest";
}

export interface ListUsersResponse {
  $type: "deeplay.jackpoker_exchequer.v1.ListUsersResponse";
  items: User[];
}

export interface SubscribeToUsersRequest {
  $type: "deeplay.jackpoker_exchequer.v1.SubscribeToUsersRequest";
}

export interface SubscribeToUsersResponse {
  $type: "deeplay.jackpoker_exchequer.v1.SubscribeToUsersResponse";
  updates: SubscribeToUsersResponse_Update[];
}

export interface SubscribeToUsersResponse_Update {
  $type: "deeplay.jackpoker_exchequer.v1.SubscribeToUsersResponse.Update";
  /** Ключ - user ID */
  key: string;
  value: User | undefined;
}

export interface GetUserStatisticsRequest {
  $type: "deeplay.jackpoker_exchequer.v1.GetUserStatisticsRequest";
  /**
   * Ожидает даты в формате 'yyyy-MM-dd', пример: 2022-06-08 (8 июня 2022),
   * при отсутствии дат бросает INVALID_ARGUMENT.
   */
  startDate: string;
  endDate: string;
  userIds: string[];
  affiliateId: string;
}

/**
 * Статистика игрока за определённый промежуток времени с точностью до дня no
 * UTC. Для cash показателей учитываются законченные в указанный период
 * столосессии (т.е. игрок сел и покинул стол). Для турнирных показателей
 * учитываются турниры, которые завершились в указанный период. Денежные
 * значения выражены в центах.
 */
export interface UserStatistics {
  $type: "deeplay.jackpoker_exchequer.v1.UserStatistics";
  id: string;
  cashResult: Long;
  cashRake: Long;
  cashHands: Long;
  tournamentResult: Long;
  tournamentFee: Long;
  tournamentHands: Long;
  tournamentRake: Long;
  deposits: Long;
  cashouts: Long;
  profit: Long;
}

export interface GetUserStatisticsResponse {
  $type: "deeplay.jackpoker_exchequer.v1.GetUserStatisticsResponse";
  items: UserStatistics[];
}

export interface AffiliateDeal {
  $type: "deeplay.jackpoker_exchequer.v1.AffiliateDeal";
  /** ID сделки */
  id: string;
  /** ID агента, с которым обозначена сделка */
  affiliateId: string;
  /** Человекочитаемое название сделки */
  name: string;
  /**
   * Отсортированная по времени коллекция условий сделки, включая тип и период,
   * в который сделка действительна
   */
  terms: Term[];
  /** Отсортированная по времени коллекция привязанных к сделке линков */
  links: AssignedLink[];
}

export interface AssignedLink {
  $type: "deeplay.jackpoker_exchequer.v1.AssignedLink";
  startDate: Date | undefined;
  id: string;
  content: string;
}

export interface SubscribeToAffiliateDealsRequest {
  $type: "deeplay.jackpoker_exchequer.v1.SubscribeToAffiliateDealsRequest";
}

export interface SubscribeToAffiliateDealsResponse {
  $type: "deeplay.jackpoker_exchequer.v1.SubscribeToAffiliateDealsResponse";
  updates: SubscribeToAffiliateDealsResponse_Update[];
}

export interface SubscribeToAffiliateDealsResponse_Update {
  $type: "deeplay.jackpoker_exchequer.v1.SubscribeToAffiliateDealsResponse.Update";
  /** Ключ - deal_id */
  key: string;
  value: AffiliateDeal | undefined;
}

export interface GetAffiliateDealsRequest {
  $type: "deeplay.jackpoker_exchequer.v1.GetAffiliateDealsRequest";
}

export interface GetAffiliateDealsResponse {
  $type: "deeplay.jackpoker_exchequer.v1.GetAffiliateDealsResponse";
  items: AffiliateDeal[];
}

export interface SaveAffiliateDealRequest {
  $type: "deeplay.jackpoker_exchequer.v1.SaveAffiliateDealRequest";
  action?:
    | { $case: "create"; create: SaveAffiliateDealRequest_Create }
    | { $case: "update"; update: SaveAffiliateDealRequest_Update }
    | { $case: "delete"; delete: SaveAffiliateDealRequest_Delete };
}

export interface SaveAffiliateDealRequest_Update {
  $type: "deeplay.jackpoker_exchequer.v1.SaveAffiliateDealRequest.Update";
  deal: AffiliateDeal | undefined;
}

export interface SaveAffiliateDealRequest_Create {
  $type: "deeplay.jackpoker_exchequer.v1.SaveAffiliateDealRequest.Create";
  deal: AffiliateDeal | undefined;
}

export interface SaveAffiliateDealRequest_Delete {
  $type: "deeplay.jackpoker_exchequer.v1.SaveAffiliateDealRequest.Delete";
  dealId: string;
}

export interface SaveAffiliateDealResponse {
  $type: "deeplay.jackpoker_exchequer.v1.SaveAffiliateDealResponse";
}

export interface GetDealHistoryRequest {
  $type: "deeplay.jackpoker_exchequer.v1.GetDealHistoryRequest";
  dealId: string;
}

export interface GetDealHistoryResponse {
  $type: "deeplay.jackpoker_exchequer.v1.GetDealHistoryResponse";
  items: GetDealHistoryResponse_DealVersion[];
}

export interface GetDealHistoryResponse_DealVersion {
  $type: "deeplay.jackpoker_exchequer.v1.GetDealHistoryResponse.DealVersion";
  deal: AffiliateDeal | undefined;
  updatedAt: Date | undefined;
  deleted: boolean;
}

export interface GetLinkHistoryRequest {
  $type: "deeplay.jackpoker_exchequer.v1.GetLinkHistoryRequest";
  linkId: string;
  dealId: string;
}

export interface GetLinkHistoryResponse {
  $type: "deeplay.jackpoker_exchequer.v1.GetLinkHistoryResponse";
  items: GetLinkHistoryResponse_LinkVersion[];
}

export interface GetLinkHistoryResponse_LinkVersion {
  $type: "deeplay.jackpoker_exchequer.v1.GetLinkHistoryResponse.LinkVersion";
  link: AssignedLink | undefined;
  updatedAt: Date | undefined;
  deleted: boolean;
}

export interface SubscribeToLinksRequest {
  $type: "deeplay.jackpoker_exchequer.v1.SubscribeToLinksRequest";
}

export interface SubscribeToLinksResponse {
  $type: "deeplay.jackpoker_exchequer.v1.SubscribeToLinksResponse";
  updates: SubscribeToLinksResponse_Update[];
}

export interface SubscribeToLinksResponse_Update {
  $type: "deeplay.jackpoker_exchequer.v1.SubscribeToLinksResponse.Update";
  /** Ключ - link_id */
  key: string;
  value: Link | undefined;
}

function createBaseUser(): User {
  return {
    $type: "deeplay.jackpoker_exchequer.v1.User",
    id: "",
    name: "",
    agentId: undefined,
    updatedAt: undefined,
  };
}

export const User = {
  $type: "deeplay.jackpoker_exchequer.v1.User" as const,

  encode(message: User, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.agentId !== undefined) {
      writer.uint32(26).string(message.agentId);
    }
    if (message.updatedAt !== undefined) {
      Timestamp.encode(
        toTimestamp(message.updatedAt),
        writer.uint32(34).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): User {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.name = reader.string();
          break;
        case 3:
          message.agentId = reader.string();
          break;
        case 4:
          message.updatedAt = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<User>): User {
    const message = createBaseUser();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.agentId = object.agentId ?? undefined;
    message.updatedAt = object.updatedAt ?? undefined;
    return message;
  },
};

messageTypeRegistry.set(User.$type, User);

function createBaseListUsersRequest(): ListUsersRequest {
  return { $type: "deeplay.jackpoker_exchequer.v1.ListUsersRequest" };
}

export const ListUsersRequest = {
  $type: "deeplay.jackpoker_exchequer.v1.ListUsersRequest" as const,

  encode(
    _: ListUsersRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListUsersRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListUsersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<ListUsersRequest>): ListUsersRequest {
    const message = createBaseListUsersRequest();
    return message;
  },
};

messageTypeRegistry.set(ListUsersRequest.$type, ListUsersRequest);

function createBaseListUsersResponse(): ListUsersResponse {
  return {
    $type: "deeplay.jackpoker_exchequer.v1.ListUsersResponse",
    items: [],
  };
}

export const ListUsersResponse = {
  $type: "deeplay.jackpoker_exchequer.v1.ListUsersResponse" as const,

  encode(
    message: ListUsersResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.items) {
      User.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListUsersResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListUsersResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.items.push(User.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<ListUsersResponse>): ListUsersResponse {
    const message = createBaseListUsersResponse();
    message.items = object.items?.map((e) => User.fromPartial(e)) || [];
    return message;
  },
};

messageTypeRegistry.set(ListUsersResponse.$type, ListUsersResponse);

function createBaseSubscribeToUsersRequest(): SubscribeToUsersRequest {
  return { $type: "deeplay.jackpoker_exchequer.v1.SubscribeToUsersRequest" };
}

export const SubscribeToUsersRequest = {
  $type: "deeplay.jackpoker_exchequer.v1.SubscribeToUsersRequest" as const,

  encode(
    _: SubscribeToUsersRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): SubscribeToUsersRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubscribeToUsersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    _: DeepPartial<SubscribeToUsersRequest>
  ): SubscribeToUsersRequest {
    const message = createBaseSubscribeToUsersRequest();
    return message;
  },
};

messageTypeRegistry.set(SubscribeToUsersRequest.$type, SubscribeToUsersRequest);

function createBaseSubscribeToUsersResponse(): SubscribeToUsersResponse {
  return {
    $type: "deeplay.jackpoker_exchequer.v1.SubscribeToUsersResponse",
    updates: [],
  };
}

export const SubscribeToUsersResponse = {
  $type: "deeplay.jackpoker_exchequer.v1.SubscribeToUsersResponse" as const,

  encode(
    message: SubscribeToUsersResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.updates) {
      SubscribeToUsersResponse_Update.encode(
        v!,
        writer.uint32(10).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): SubscribeToUsersResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubscribeToUsersResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.updates.push(
            SubscribeToUsersResponse_Update.decode(reader, reader.uint32())
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<SubscribeToUsersResponse>
  ): SubscribeToUsersResponse {
    const message = createBaseSubscribeToUsersResponse();
    message.updates =
      object.updates?.map((e) =>
        SubscribeToUsersResponse_Update.fromPartial(e)
      ) || [];
    return message;
  },
};

messageTypeRegistry.set(
  SubscribeToUsersResponse.$type,
  SubscribeToUsersResponse
);

function createBaseSubscribeToUsersResponse_Update(): SubscribeToUsersResponse_Update {
  return {
    $type: "deeplay.jackpoker_exchequer.v1.SubscribeToUsersResponse.Update",
    key: "",
    value: undefined,
  };
}

export const SubscribeToUsersResponse_Update = {
  $type:
    "deeplay.jackpoker_exchequer.v1.SubscribeToUsersResponse.Update" as const,

  encode(
    message: SubscribeToUsersResponse_Update,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      User.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): SubscribeToUsersResponse_Update {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubscribeToUsersResponse_Update();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.key = reader.string();
          break;
        case 2:
          message.value = User.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<SubscribeToUsersResponse_Update>
  ): SubscribeToUsersResponse_Update {
    const message = createBaseSubscribeToUsersResponse_Update();
    message.key = object.key ?? "";
    message.value =
      object.value !== undefined && object.value !== null
        ? User.fromPartial(object.value)
        : undefined;
    return message;
  },
};

messageTypeRegistry.set(
  SubscribeToUsersResponse_Update.$type,
  SubscribeToUsersResponse_Update
);

function createBaseGetUserStatisticsRequest(): GetUserStatisticsRequest {
  return {
    $type: "deeplay.jackpoker_exchequer.v1.GetUserStatisticsRequest",
    startDate: "",
    endDate: "",
    userIds: [],
    affiliateId: "",
  };
}

export const GetUserStatisticsRequest = {
  $type: "deeplay.jackpoker_exchequer.v1.GetUserStatisticsRequest" as const,

  encode(
    message: GetUserStatisticsRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.startDate !== "") {
      writer.uint32(10).string(message.startDate);
    }
    if (message.endDate !== "") {
      writer.uint32(18).string(message.endDate);
    }
    for (const v of message.userIds) {
      writer.uint32(26).string(v!);
    }
    if (message.affiliateId !== "") {
      writer.uint32(34).string(message.affiliateId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): GetUserStatisticsRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserStatisticsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.startDate = reader.string();
          break;
        case 2:
          message.endDate = reader.string();
          break;
        case 3:
          message.userIds.push(reader.string());
          break;
        case 4:
          message.affiliateId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<GetUserStatisticsRequest>
  ): GetUserStatisticsRequest {
    const message = createBaseGetUserStatisticsRequest();
    message.startDate = object.startDate ?? "";
    message.endDate = object.endDate ?? "";
    message.userIds = object.userIds?.map((e) => e) || [];
    message.affiliateId = object.affiliateId ?? "";
    return message;
  },
};

messageTypeRegistry.set(
  GetUserStatisticsRequest.$type,
  GetUserStatisticsRequest
);

function createBaseUserStatistics(): UserStatistics {
  return {
    $type: "deeplay.jackpoker_exchequer.v1.UserStatistics",
    id: "",
    cashResult: Long.ZERO,
    cashRake: Long.UZERO,
    cashHands: Long.UZERO,
    tournamentResult: Long.ZERO,
    tournamentFee: Long.UZERO,
    tournamentHands: Long.UZERO,
    tournamentRake: Long.UZERO,
    deposits: Long.UZERO,
    cashouts: Long.ZERO,
    profit: Long.ZERO,
  };
}

export const UserStatistics = {
  $type: "deeplay.jackpoker_exchequer.v1.UserStatistics" as const,

  encode(
    message: UserStatistics,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (!message.cashResult.isZero()) {
      writer.uint32(16).sint64(message.cashResult);
    }
    if (!message.cashRake.isZero()) {
      writer.uint32(24).uint64(message.cashRake);
    }
    if (!message.cashHands.isZero()) {
      writer.uint32(32).uint64(message.cashHands);
    }
    if (!message.tournamentResult.isZero()) {
      writer.uint32(40).sint64(message.tournamentResult);
    }
    if (!message.tournamentFee.isZero()) {
      writer.uint32(48).uint64(message.tournamentFee);
    }
    if (!message.tournamentHands.isZero()) {
      writer.uint32(56).uint64(message.tournamentHands);
    }
    if (!message.tournamentRake.isZero()) {
      writer.uint32(88).uint64(message.tournamentRake);
    }
    if (!message.deposits.isZero()) {
      writer.uint32(64).uint64(message.deposits);
    }
    if (!message.cashouts.isZero()) {
      writer.uint32(72).sint64(message.cashouts);
    }
    if (!message.profit.isZero()) {
      writer.uint32(80).sint64(message.profit);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserStatistics {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserStatistics();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.cashResult = reader.sint64() as Long;
          break;
        case 3:
          message.cashRake = reader.uint64() as Long;
          break;
        case 4:
          message.cashHands = reader.uint64() as Long;
          break;
        case 5:
          message.tournamentResult = reader.sint64() as Long;
          break;
        case 6:
          message.tournamentFee = reader.uint64() as Long;
          break;
        case 7:
          message.tournamentHands = reader.uint64() as Long;
          break;
        case 11:
          message.tournamentRake = reader.uint64() as Long;
          break;
        case 8:
          message.deposits = reader.uint64() as Long;
          break;
        case 9:
          message.cashouts = reader.sint64() as Long;
          break;
        case 10:
          message.profit = reader.sint64() as Long;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<UserStatistics>): UserStatistics {
    const message = createBaseUserStatistics();
    message.id = object.id ?? "";
    message.cashResult =
      object.cashResult !== undefined && object.cashResult !== null
        ? Long.fromValue(object.cashResult)
        : Long.ZERO;
    message.cashRake =
      object.cashRake !== undefined && object.cashRake !== null
        ? Long.fromValue(object.cashRake)
        : Long.UZERO;
    message.cashHands =
      object.cashHands !== undefined && object.cashHands !== null
        ? Long.fromValue(object.cashHands)
        : Long.UZERO;
    message.tournamentResult =
      object.tournamentResult !== undefined && object.tournamentResult !== null
        ? Long.fromValue(object.tournamentResult)
        : Long.ZERO;
    message.tournamentFee =
      object.tournamentFee !== undefined && object.tournamentFee !== null
        ? Long.fromValue(object.tournamentFee)
        : Long.UZERO;
    message.tournamentHands =
      object.tournamentHands !== undefined && object.tournamentHands !== null
        ? Long.fromValue(object.tournamentHands)
        : Long.UZERO;
    message.tournamentRake =
      object.tournamentRake !== undefined && object.tournamentRake !== null
        ? Long.fromValue(object.tournamentRake)
        : Long.UZERO;
    message.deposits =
      object.deposits !== undefined && object.deposits !== null
        ? Long.fromValue(object.deposits)
        : Long.UZERO;
    message.cashouts =
      object.cashouts !== undefined && object.cashouts !== null
        ? Long.fromValue(object.cashouts)
        : Long.ZERO;
    message.profit =
      object.profit !== undefined && object.profit !== null
        ? Long.fromValue(object.profit)
        : Long.ZERO;
    return message;
  },
};

messageTypeRegistry.set(UserStatistics.$type, UserStatistics);

function createBaseGetUserStatisticsResponse(): GetUserStatisticsResponse {
  return {
    $type: "deeplay.jackpoker_exchequer.v1.GetUserStatisticsResponse",
    items: [],
  };
}

export const GetUserStatisticsResponse = {
  $type: "deeplay.jackpoker_exchequer.v1.GetUserStatisticsResponse" as const,

  encode(
    message: GetUserStatisticsResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.items) {
      UserStatistics.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): GetUserStatisticsResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserStatisticsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.items.push(UserStatistics.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<GetUserStatisticsResponse>
  ): GetUserStatisticsResponse {
    const message = createBaseGetUserStatisticsResponse();
    message.items =
      object.items?.map((e) => UserStatistics.fromPartial(e)) || [];
    return message;
  },
};

messageTypeRegistry.set(
  GetUserStatisticsResponse.$type,
  GetUserStatisticsResponse
);

function createBaseAffiliateDeal(): AffiliateDeal {
  return {
    $type: "deeplay.jackpoker_exchequer.v1.AffiliateDeal",
    id: "",
    affiliateId: "",
    name: "",
    terms: [],
    links: [],
  };
}

export const AffiliateDeal = {
  $type: "deeplay.jackpoker_exchequer.v1.AffiliateDeal" as const,

  encode(
    message: AffiliateDeal,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.affiliateId !== "") {
      writer.uint32(18).string(message.affiliateId);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    for (const v of message.terms) {
      Term.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.links) {
      AssignedLink.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AffiliateDeal {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAffiliateDeal();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.affiliateId = reader.string();
          break;
        case 3:
          message.name = reader.string();
          break;
        case 4:
          message.terms.push(Term.decode(reader, reader.uint32()));
          break;
        case 5:
          message.links.push(AssignedLink.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<AffiliateDeal>): AffiliateDeal {
    const message = createBaseAffiliateDeal();
    message.id = object.id ?? "";
    message.affiliateId = object.affiliateId ?? "";
    message.name = object.name ?? "";
    message.terms = object.terms?.map((e) => Term.fromPartial(e)) || [];
    message.links = object.links?.map((e) => AssignedLink.fromPartial(e)) || [];
    return message;
  },
};

messageTypeRegistry.set(AffiliateDeal.$type, AffiliateDeal);

function createBaseAssignedLink(): AssignedLink {
  return {
    $type: "deeplay.jackpoker_exchequer.v1.AssignedLink",
    startDate: undefined,
    id: "",
    content: "",
  };
}

export const AssignedLink = {
  $type: "deeplay.jackpoker_exchequer.v1.AssignedLink" as const,

  encode(
    message: AssignedLink,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.startDate !== undefined) {
      Timestamp.encode(
        toTimestamp(message.startDate),
        writer.uint32(10).fork()
      ).ldelim();
    }
    if (message.id !== "") {
      writer.uint32(18).string(message.id);
    }
    if (message.content !== "") {
      writer.uint32(26).string(message.content);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssignedLink {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssignedLink();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.startDate = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          );
          break;
        case 2:
          message.id = reader.string();
          break;
        case 3:
          message.content = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<AssignedLink>): AssignedLink {
    const message = createBaseAssignedLink();
    message.startDate = object.startDate ?? undefined;
    message.id = object.id ?? "";
    message.content = object.content ?? "";
    return message;
  },
};

messageTypeRegistry.set(AssignedLink.$type, AssignedLink);

function createBaseSubscribeToAffiliateDealsRequest(): SubscribeToAffiliateDealsRequest {
  return {
    $type: "deeplay.jackpoker_exchequer.v1.SubscribeToAffiliateDealsRequest",
  };
}

export const SubscribeToAffiliateDealsRequest = {
  $type:
    "deeplay.jackpoker_exchequer.v1.SubscribeToAffiliateDealsRequest" as const,

  encode(
    _: SubscribeToAffiliateDealsRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): SubscribeToAffiliateDealsRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubscribeToAffiliateDealsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    _: DeepPartial<SubscribeToAffiliateDealsRequest>
  ): SubscribeToAffiliateDealsRequest {
    const message = createBaseSubscribeToAffiliateDealsRequest();
    return message;
  },
};

messageTypeRegistry.set(
  SubscribeToAffiliateDealsRequest.$type,
  SubscribeToAffiliateDealsRequest
);

function createBaseSubscribeToAffiliateDealsResponse(): SubscribeToAffiliateDealsResponse {
  return {
    $type: "deeplay.jackpoker_exchequer.v1.SubscribeToAffiliateDealsResponse",
    updates: [],
  };
}

export const SubscribeToAffiliateDealsResponse = {
  $type:
    "deeplay.jackpoker_exchequer.v1.SubscribeToAffiliateDealsResponse" as const,

  encode(
    message: SubscribeToAffiliateDealsResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.updates) {
      SubscribeToAffiliateDealsResponse_Update.encode(
        v!,
        writer.uint32(10).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): SubscribeToAffiliateDealsResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubscribeToAffiliateDealsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.updates.push(
            SubscribeToAffiliateDealsResponse_Update.decode(
              reader,
              reader.uint32()
            )
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<SubscribeToAffiliateDealsResponse>
  ): SubscribeToAffiliateDealsResponse {
    const message = createBaseSubscribeToAffiliateDealsResponse();
    message.updates =
      object.updates?.map((e) =>
        SubscribeToAffiliateDealsResponse_Update.fromPartial(e)
      ) || [];
    return message;
  },
};

messageTypeRegistry.set(
  SubscribeToAffiliateDealsResponse.$type,
  SubscribeToAffiliateDealsResponse
);

function createBaseSubscribeToAffiliateDealsResponse_Update(): SubscribeToAffiliateDealsResponse_Update {
  return {
    $type:
      "deeplay.jackpoker_exchequer.v1.SubscribeToAffiliateDealsResponse.Update",
    key: "",
    value: undefined,
  };
}

export const SubscribeToAffiliateDealsResponse_Update = {
  $type:
    "deeplay.jackpoker_exchequer.v1.SubscribeToAffiliateDealsResponse.Update" as const,

  encode(
    message: SubscribeToAffiliateDealsResponse_Update,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      AffiliateDeal.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): SubscribeToAffiliateDealsResponse_Update {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubscribeToAffiliateDealsResponse_Update();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.key = reader.string();
          break;
        case 2:
          message.value = AffiliateDeal.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<SubscribeToAffiliateDealsResponse_Update>
  ): SubscribeToAffiliateDealsResponse_Update {
    const message = createBaseSubscribeToAffiliateDealsResponse_Update();
    message.key = object.key ?? "";
    message.value =
      object.value !== undefined && object.value !== null
        ? AffiliateDeal.fromPartial(object.value)
        : undefined;
    return message;
  },
};

messageTypeRegistry.set(
  SubscribeToAffiliateDealsResponse_Update.$type,
  SubscribeToAffiliateDealsResponse_Update
);

function createBaseGetAffiliateDealsRequest(): GetAffiliateDealsRequest {
  return { $type: "deeplay.jackpoker_exchequer.v1.GetAffiliateDealsRequest" };
}

export const GetAffiliateDealsRequest = {
  $type: "deeplay.jackpoker_exchequer.v1.GetAffiliateDealsRequest" as const,

  encode(
    _: GetAffiliateDealsRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): GetAffiliateDealsRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAffiliateDealsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    _: DeepPartial<GetAffiliateDealsRequest>
  ): GetAffiliateDealsRequest {
    const message = createBaseGetAffiliateDealsRequest();
    return message;
  },
};

messageTypeRegistry.set(
  GetAffiliateDealsRequest.$type,
  GetAffiliateDealsRequest
);

function createBaseGetAffiliateDealsResponse(): GetAffiliateDealsResponse {
  return {
    $type: "deeplay.jackpoker_exchequer.v1.GetAffiliateDealsResponse",
    items: [],
  };
}

export const GetAffiliateDealsResponse = {
  $type: "deeplay.jackpoker_exchequer.v1.GetAffiliateDealsResponse" as const,

  encode(
    message: GetAffiliateDealsResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.items) {
      AffiliateDeal.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): GetAffiliateDealsResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAffiliateDealsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.items.push(AffiliateDeal.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<GetAffiliateDealsResponse>
  ): GetAffiliateDealsResponse {
    const message = createBaseGetAffiliateDealsResponse();
    message.items =
      object.items?.map((e) => AffiliateDeal.fromPartial(e)) || [];
    return message;
  },
};

messageTypeRegistry.set(
  GetAffiliateDealsResponse.$type,
  GetAffiliateDealsResponse
);

function createBaseSaveAffiliateDealRequest(): SaveAffiliateDealRequest {
  return {
    $type: "deeplay.jackpoker_exchequer.v1.SaveAffiliateDealRequest",
    action: undefined,
  };
}

export const SaveAffiliateDealRequest = {
  $type: "deeplay.jackpoker_exchequer.v1.SaveAffiliateDealRequest" as const,

  encode(
    message: SaveAffiliateDealRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.action?.$case === "create") {
      SaveAffiliateDealRequest_Create.encode(
        message.action.create,
        writer.uint32(10).fork()
      ).ldelim();
    }
    if (message.action?.$case === "update") {
      SaveAffiliateDealRequest_Update.encode(
        message.action.update,
        writer.uint32(18).fork()
      ).ldelim();
    }
    if (message.action?.$case === "delete") {
      SaveAffiliateDealRequest_Delete.encode(
        message.action.delete,
        writer.uint32(26).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): SaveAffiliateDealRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSaveAffiliateDealRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.action = {
            $case: "create",
            create: SaveAffiliateDealRequest_Create.decode(
              reader,
              reader.uint32()
            ),
          };
          break;
        case 2:
          message.action = {
            $case: "update",
            update: SaveAffiliateDealRequest_Update.decode(
              reader,
              reader.uint32()
            ),
          };
          break;
        case 3:
          message.action = {
            $case: "delete",
            delete: SaveAffiliateDealRequest_Delete.decode(
              reader,
              reader.uint32()
            ),
          };
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<SaveAffiliateDealRequest>
  ): SaveAffiliateDealRequest {
    const message = createBaseSaveAffiliateDealRequest();
    if (
      object.action?.$case === "create" &&
      object.action?.create !== undefined &&
      object.action?.create !== null
    ) {
      message.action = {
        $case: "create",
        create: SaveAffiliateDealRequest_Create.fromPartial(
          object.action.create
        ),
      };
    }
    if (
      object.action?.$case === "update" &&
      object.action?.update !== undefined &&
      object.action?.update !== null
    ) {
      message.action = {
        $case: "update",
        update: SaveAffiliateDealRequest_Update.fromPartial(
          object.action.update
        ),
      };
    }
    if (
      object.action?.$case === "delete" &&
      object.action?.delete !== undefined &&
      object.action?.delete !== null
    ) {
      message.action = {
        $case: "delete",
        delete: SaveAffiliateDealRequest_Delete.fromPartial(
          object.action.delete
        ),
      };
    }
    return message;
  },
};

messageTypeRegistry.set(
  SaveAffiliateDealRequest.$type,
  SaveAffiliateDealRequest
);

function createBaseSaveAffiliateDealRequest_Update(): SaveAffiliateDealRequest_Update {
  return {
    $type: "deeplay.jackpoker_exchequer.v1.SaveAffiliateDealRequest.Update",
    deal: undefined,
  };
}

export const SaveAffiliateDealRequest_Update = {
  $type:
    "deeplay.jackpoker_exchequer.v1.SaveAffiliateDealRequest.Update" as const,

  encode(
    message: SaveAffiliateDealRequest_Update,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.deal !== undefined) {
      AffiliateDeal.encode(message.deal, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): SaveAffiliateDealRequest_Update {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSaveAffiliateDealRequest_Update();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.deal = AffiliateDeal.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<SaveAffiliateDealRequest_Update>
  ): SaveAffiliateDealRequest_Update {
    const message = createBaseSaveAffiliateDealRequest_Update();
    message.deal =
      object.deal !== undefined && object.deal !== null
        ? AffiliateDeal.fromPartial(object.deal)
        : undefined;
    return message;
  },
};

messageTypeRegistry.set(
  SaveAffiliateDealRequest_Update.$type,
  SaveAffiliateDealRequest_Update
);

function createBaseSaveAffiliateDealRequest_Create(): SaveAffiliateDealRequest_Create {
  return {
    $type: "deeplay.jackpoker_exchequer.v1.SaveAffiliateDealRequest.Create",
    deal: undefined,
  };
}

export const SaveAffiliateDealRequest_Create = {
  $type:
    "deeplay.jackpoker_exchequer.v1.SaveAffiliateDealRequest.Create" as const,

  encode(
    message: SaveAffiliateDealRequest_Create,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.deal !== undefined) {
      AffiliateDeal.encode(message.deal, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): SaveAffiliateDealRequest_Create {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSaveAffiliateDealRequest_Create();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.deal = AffiliateDeal.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<SaveAffiliateDealRequest_Create>
  ): SaveAffiliateDealRequest_Create {
    const message = createBaseSaveAffiliateDealRequest_Create();
    message.deal =
      object.deal !== undefined && object.deal !== null
        ? AffiliateDeal.fromPartial(object.deal)
        : undefined;
    return message;
  },
};

messageTypeRegistry.set(
  SaveAffiliateDealRequest_Create.$type,
  SaveAffiliateDealRequest_Create
);

function createBaseSaveAffiliateDealRequest_Delete(): SaveAffiliateDealRequest_Delete {
  return {
    $type: "deeplay.jackpoker_exchequer.v1.SaveAffiliateDealRequest.Delete",
    dealId: "",
  };
}

export const SaveAffiliateDealRequest_Delete = {
  $type:
    "deeplay.jackpoker_exchequer.v1.SaveAffiliateDealRequest.Delete" as const,

  encode(
    message: SaveAffiliateDealRequest_Delete,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.dealId !== "") {
      writer.uint32(10).string(message.dealId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): SaveAffiliateDealRequest_Delete {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSaveAffiliateDealRequest_Delete();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.dealId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<SaveAffiliateDealRequest_Delete>
  ): SaveAffiliateDealRequest_Delete {
    const message = createBaseSaveAffiliateDealRequest_Delete();
    message.dealId = object.dealId ?? "";
    return message;
  },
};

messageTypeRegistry.set(
  SaveAffiliateDealRequest_Delete.$type,
  SaveAffiliateDealRequest_Delete
);

function createBaseSaveAffiliateDealResponse(): SaveAffiliateDealResponse {
  return { $type: "deeplay.jackpoker_exchequer.v1.SaveAffiliateDealResponse" };
}

export const SaveAffiliateDealResponse = {
  $type: "deeplay.jackpoker_exchequer.v1.SaveAffiliateDealResponse" as const,

  encode(
    _: SaveAffiliateDealResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): SaveAffiliateDealResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSaveAffiliateDealResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    _: DeepPartial<SaveAffiliateDealResponse>
  ): SaveAffiliateDealResponse {
    const message = createBaseSaveAffiliateDealResponse();
    return message;
  },
};

messageTypeRegistry.set(
  SaveAffiliateDealResponse.$type,
  SaveAffiliateDealResponse
);

function createBaseGetDealHistoryRequest(): GetDealHistoryRequest {
  return {
    $type: "deeplay.jackpoker_exchequer.v1.GetDealHistoryRequest",
    dealId: "",
  };
}

export const GetDealHistoryRequest = {
  $type: "deeplay.jackpoker_exchequer.v1.GetDealHistoryRequest" as const,

  encode(
    message: GetDealHistoryRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.dealId !== "") {
      writer.uint32(10).string(message.dealId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): GetDealHistoryRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDealHistoryRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.dealId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<GetDealHistoryRequest>
  ): GetDealHistoryRequest {
    const message = createBaseGetDealHistoryRequest();
    message.dealId = object.dealId ?? "";
    return message;
  },
};

messageTypeRegistry.set(GetDealHistoryRequest.$type, GetDealHistoryRequest);

function createBaseGetDealHistoryResponse(): GetDealHistoryResponse {
  return {
    $type: "deeplay.jackpoker_exchequer.v1.GetDealHistoryResponse",
    items: [],
  };
}

export const GetDealHistoryResponse = {
  $type: "deeplay.jackpoker_exchequer.v1.GetDealHistoryResponse" as const,

  encode(
    message: GetDealHistoryResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.items) {
      GetDealHistoryResponse_DealVersion.encode(
        v!,
        writer.uint32(10).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): GetDealHistoryResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDealHistoryResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.items.push(
            GetDealHistoryResponse_DealVersion.decode(reader, reader.uint32())
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<GetDealHistoryResponse>
  ): GetDealHistoryResponse {
    const message = createBaseGetDealHistoryResponse();
    message.items =
      object.items?.map((e) =>
        GetDealHistoryResponse_DealVersion.fromPartial(e)
      ) || [];
    return message;
  },
};

messageTypeRegistry.set(GetDealHistoryResponse.$type, GetDealHistoryResponse);

function createBaseGetDealHistoryResponse_DealVersion(): GetDealHistoryResponse_DealVersion {
  return {
    $type: "deeplay.jackpoker_exchequer.v1.GetDealHistoryResponse.DealVersion",
    deal: undefined,
    updatedAt: undefined,
    deleted: false,
  };
}

export const GetDealHistoryResponse_DealVersion = {
  $type:
    "deeplay.jackpoker_exchequer.v1.GetDealHistoryResponse.DealVersion" as const,

  encode(
    message: GetDealHistoryResponse_DealVersion,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.deal !== undefined) {
      AffiliateDeal.encode(message.deal, writer.uint32(10).fork()).ldelim();
    }
    if (message.updatedAt !== undefined) {
      Timestamp.encode(
        toTimestamp(message.updatedAt),
        writer.uint32(18).fork()
      ).ldelim();
    }
    if (message.deleted === true) {
      writer.uint32(24).bool(message.deleted);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): GetDealHistoryResponse_DealVersion {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDealHistoryResponse_DealVersion();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.deal = AffiliateDeal.decode(reader, reader.uint32());
          break;
        case 2:
          message.updatedAt = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          );
          break;
        case 3:
          message.deleted = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<GetDealHistoryResponse_DealVersion>
  ): GetDealHistoryResponse_DealVersion {
    const message = createBaseGetDealHistoryResponse_DealVersion();
    message.deal =
      object.deal !== undefined && object.deal !== null
        ? AffiliateDeal.fromPartial(object.deal)
        : undefined;
    message.updatedAt = object.updatedAt ?? undefined;
    message.deleted = object.deleted ?? false;
    return message;
  },
};

messageTypeRegistry.set(
  GetDealHistoryResponse_DealVersion.$type,
  GetDealHistoryResponse_DealVersion
);

function createBaseGetLinkHistoryRequest(): GetLinkHistoryRequest {
  return {
    $type: "deeplay.jackpoker_exchequer.v1.GetLinkHistoryRequest",
    linkId: "",
    dealId: "",
  };
}

export const GetLinkHistoryRequest = {
  $type: "deeplay.jackpoker_exchequer.v1.GetLinkHistoryRequest" as const,

  encode(
    message: GetLinkHistoryRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.linkId !== "") {
      writer.uint32(10).string(message.linkId);
    }
    if (message.dealId !== "") {
      writer.uint32(18).string(message.dealId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): GetLinkHistoryRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetLinkHistoryRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.linkId = reader.string();
          break;
        case 2:
          message.dealId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<GetLinkHistoryRequest>
  ): GetLinkHistoryRequest {
    const message = createBaseGetLinkHistoryRequest();
    message.linkId = object.linkId ?? "";
    message.dealId = object.dealId ?? "";
    return message;
  },
};

messageTypeRegistry.set(GetLinkHistoryRequest.$type, GetLinkHistoryRequest);

function createBaseGetLinkHistoryResponse(): GetLinkHistoryResponse {
  return {
    $type: "deeplay.jackpoker_exchequer.v1.GetLinkHistoryResponse",
    items: [],
  };
}

export const GetLinkHistoryResponse = {
  $type: "deeplay.jackpoker_exchequer.v1.GetLinkHistoryResponse" as const,

  encode(
    message: GetLinkHistoryResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.items) {
      GetLinkHistoryResponse_LinkVersion.encode(
        v!,
        writer.uint32(10).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): GetLinkHistoryResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetLinkHistoryResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.items.push(
            GetLinkHistoryResponse_LinkVersion.decode(reader, reader.uint32())
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<GetLinkHistoryResponse>
  ): GetLinkHistoryResponse {
    const message = createBaseGetLinkHistoryResponse();
    message.items =
      object.items?.map((e) =>
        GetLinkHistoryResponse_LinkVersion.fromPartial(e)
      ) || [];
    return message;
  },
};

messageTypeRegistry.set(GetLinkHistoryResponse.$type, GetLinkHistoryResponse);

function createBaseGetLinkHistoryResponse_LinkVersion(): GetLinkHistoryResponse_LinkVersion {
  return {
    $type: "deeplay.jackpoker_exchequer.v1.GetLinkHistoryResponse.LinkVersion",
    link: undefined,
    updatedAt: undefined,
    deleted: false,
  };
}

export const GetLinkHistoryResponse_LinkVersion = {
  $type:
    "deeplay.jackpoker_exchequer.v1.GetLinkHistoryResponse.LinkVersion" as const,

  encode(
    message: GetLinkHistoryResponse_LinkVersion,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.link !== undefined) {
      AssignedLink.encode(message.link, writer.uint32(10).fork()).ldelim();
    }
    if (message.updatedAt !== undefined) {
      Timestamp.encode(
        toTimestamp(message.updatedAt),
        writer.uint32(18).fork()
      ).ldelim();
    }
    if (message.deleted === true) {
      writer.uint32(24).bool(message.deleted);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): GetLinkHistoryResponse_LinkVersion {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetLinkHistoryResponse_LinkVersion();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.link = AssignedLink.decode(reader, reader.uint32());
          break;
        case 2:
          message.updatedAt = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          );
          break;
        case 3:
          message.deleted = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<GetLinkHistoryResponse_LinkVersion>
  ): GetLinkHistoryResponse_LinkVersion {
    const message = createBaseGetLinkHistoryResponse_LinkVersion();
    message.link =
      object.link !== undefined && object.link !== null
        ? AssignedLink.fromPartial(object.link)
        : undefined;
    message.updatedAt = object.updatedAt ?? undefined;
    message.deleted = object.deleted ?? false;
    return message;
  },
};

messageTypeRegistry.set(
  GetLinkHistoryResponse_LinkVersion.$type,
  GetLinkHistoryResponse_LinkVersion
);

function createBaseSubscribeToLinksRequest(): SubscribeToLinksRequest {
  return { $type: "deeplay.jackpoker_exchequer.v1.SubscribeToLinksRequest" };
}

export const SubscribeToLinksRequest = {
  $type: "deeplay.jackpoker_exchequer.v1.SubscribeToLinksRequest" as const,

  encode(
    _: SubscribeToLinksRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): SubscribeToLinksRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubscribeToLinksRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    _: DeepPartial<SubscribeToLinksRequest>
  ): SubscribeToLinksRequest {
    const message = createBaseSubscribeToLinksRequest();
    return message;
  },
};

messageTypeRegistry.set(SubscribeToLinksRequest.$type, SubscribeToLinksRequest);

function createBaseSubscribeToLinksResponse(): SubscribeToLinksResponse {
  return {
    $type: "deeplay.jackpoker_exchequer.v1.SubscribeToLinksResponse",
    updates: [],
  };
}

export const SubscribeToLinksResponse = {
  $type: "deeplay.jackpoker_exchequer.v1.SubscribeToLinksResponse" as const,

  encode(
    message: SubscribeToLinksResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.updates) {
      SubscribeToLinksResponse_Update.encode(
        v!,
        writer.uint32(10).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): SubscribeToLinksResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubscribeToLinksResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.updates.push(
            SubscribeToLinksResponse_Update.decode(reader, reader.uint32())
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<SubscribeToLinksResponse>
  ): SubscribeToLinksResponse {
    const message = createBaseSubscribeToLinksResponse();
    message.updates =
      object.updates?.map((e) =>
        SubscribeToLinksResponse_Update.fromPartial(e)
      ) || [];
    return message;
  },
};

messageTypeRegistry.set(
  SubscribeToLinksResponse.$type,
  SubscribeToLinksResponse
);

function createBaseSubscribeToLinksResponse_Update(): SubscribeToLinksResponse_Update {
  return {
    $type: "deeplay.jackpoker_exchequer.v1.SubscribeToLinksResponse.Update",
    key: "",
    value: undefined,
  };
}

export const SubscribeToLinksResponse_Update = {
  $type:
    "deeplay.jackpoker_exchequer.v1.SubscribeToLinksResponse.Update" as const,

  encode(
    message: SubscribeToLinksResponse_Update,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Link.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): SubscribeToLinksResponse_Update {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubscribeToLinksResponse_Update();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.key = reader.string();
          break;
        case 2:
          message.value = Link.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<SubscribeToLinksResponse_Update>
  ): SubscribeToLinksResponse_Update {
    const message = createBaseSubscribeToLinksResponse_Update();
    message.key = object.key ?? "";
    message.value =
      object.value !== undefined && object.value !== null
        ? Link.fromPartial(object.value)
        : undefined;
    return message;
  },
};

messageTypeRegistry.set(
  SubscribeToLinksResponse_Update.$type,
  SubscribeToLinksResponse_Update
);

export type JackPokerExchequerDefinition = typeof JackPokerExchequerDefinition;
export const JackPokerExchequerDefinition = {
  name: "JackPokerExchequer",
  fullName: "deeplay.jackpoker_exchequer.v1.JackPokerExchequer",
  methods: {
    listUsers: {
      name: "ListUsers",
      requestType: ListUsersRequest,
      requestStream: false,
      responseType: ListUsersResponse,
      responseStream: false,
      options: {
        idempotencyLevel: "NO_SIDE_EFFECTS",
      },
    },
    getUserStatistics: {
      name: "GetUserStatistics",
      requestType: GetUserStatisticsRequest,
      requestStream: false,
      responseType: GetUserStatisticsResponse,
      responseStream: false,
      options: {
        idempotencyLevel: "NO_SIDE_EFFECTS",
      },
    },
    /** Подписывается на список пользователей. Ключ подписки – userId */
    subscribeToUsers: {
      name: "SubscribeToUsers",
      requestType: SubscribeToUsersRequest,
      requestStream: false,
      responseType: SubscribeToUsersResponse,
      responseStream: true,
      options: {
        idempotencyLevel: "NO_SIDE_EFFECTS",
      },
    },
    getAffiliateDeals: {
      name: "GetAffiliateDeals",
      requestType: GetAffiliateDealsRequest,
      requestStream: false,
      responseType: GetAffiliateDealsResponse,
      responseStream: false,
      options: {
        idempotencyLevel: "NO_SIDE_EFFECTS",
      },
    },
    /** Подписывается на список агентских сделок. Ключ подписки – dealId */
    subscribeToAffiliateDeals: {
      name: "SubscribeToAffiliateDeals",
      requestType: SubscribeToAffiliateDealsRequest,
      requestStream: false,
      responseType: SubscribeToAffiliateDealsResponse,
      responseStream: true,
      options: {
        idempotencyLevel: "NO_SIDE_EFFECTS",
      },
    },
    /** Создает, обновляет или удаляет новую сделку и относящийся к ней линк. */
    saveAffiliateDeal: {
      name: "SaveAffiliateDeal",
      requestType: SaveAffiliateDealRequest,
      requestStream: false,
      responseType: SaveAffiliateDealResponse,
      responseStream: false,
      options: {
        idempotencyLevel: "NO_SIDE_EFFECTS",
      },
    },
    /**
     * Возвращает исторические и актуальную версии документа со сделкой.
     * Порядок документов - от новых к старым.
     */
    getDealHistory: {
      name: "GetDealHistory",
      requestType: GetDealHistoryRequest,
      requestStream: false,
      responseType: GetDealHistoryResponse,
      responseStream: false,
      options: {
        idempotencyLevel: "NO_SIDE_EFFECTS",
      },
    },
    /**
     * Возвращает исторические и актуальную версии документа с линком.
     * Порядок документов - от новых к старым.
     */
    getLinkHistory: {
      name: "GetLinkHistory",
      requestType: GetLinkHistoryRequest,
      requestStream: false,
      responseType: GetLinkHistoryResponse,
      responseStream: false,
      options: {
        idempotencyLevel: "NO_SIDE_EFFECTS",
      },
    },
    /**
     * Подписывается на все актуальные линки,
     * в том числе линки без привязки к сделке.
     */
    subscribeToLinks: {
      name: "SubscribeToLinks",
      requestType: SubscribeToLinksRequest,
      requestStream: false,
      responseType: SubscribeToLinksResponse,
      responseStream: true,
      options: {
        idempotencyLevel: "NO_SIDE_EFFECTS",
      },
    },
  },
} as const;

export interface JackPokerExchequerServiceImplementation<CallContextExt = {}> {
  listUsers(
    request: ListUsersRequest,
    context: CallContext & CallContextExt
  ): Promise<DeepPartial<ListUsersResponse>>;
  getUserStatistics(
    request: GetUserStatisticsRequest,
    context: CallContext & CallContextExt
  ): Promise<DeepPartial<GetUserStatisticsResponse>>;
  /** Подписывается на список пользователей. Ключ подписки – userId */
  subscribeToUsers(
    request: SubscribeToUsersRequest,
    context: CallContext & CallContextExt
  ): ServerStreamingMethodResult<DeepPartial<SubscribeToUsersResponse>>;
  getAffiliateDeals(
    request: GetAffiliateDealsRequest,
    context: CallContext & CallContextExt
  ): Promise<DeepPartial<GetAffiliateDealsResponse>>;
  /** Подписывается на список агентских сделок. Ключ подписки – dealId */
  subscribeToAffiliateDeals(
    request: SubscribeToAffiliateDealsRequest,
    context: CallContext & CallContextExt
  ): ServerStreamingMethodResult<
    DeepPartial<SubscribeToAffiliateDealsResponse>
  >;
  /** Создает, обновляет или удаляет новую сделку и относящийся к ней линк. */
  saveAffiliateDeal(
    request: SaveAffiliateDealRequest,
    context: CallContext & CallContextExt
  ): Promise<DeepPartial<SaveAffiliateDealResponse>>;
  /**
   * Возвращает исторические и актуальную версии документа со сделкой.
   * Порядок документов - от новых к старым.
   */
  getDealHistory(
    request: GetDealHistoryRequest,
    context: CallContext & CallContextExt
  ): Promise<DeepPartial<GetDealHistoryResponse>>;
  /**
   * Возвращает исторические и актуальную версии документа с линком.
   * Порядок документов - от новых к старым.
   */
  getLinkHistory(
    request: GetLinkHistoryRequest,
    context: CallContext & CallContextExt
  ): Promise<DeepPartial<GetLinkHistoryResponse>>;
  /**
   * Подписывается на все актуальные линки,
   * в том числе линки без привязки к сделке.
   */
  subscribeToLinks(
    request: SubscribeToLinksRequest,
    context: CallContext & CallContextExt
  ): ServerStreamingMethodResult<DeepPartial<SubscribeToLinksResponse>>;
}

export interface JackPokerExchequerClient<CallOptionsExt = {}> {
  listUsers(
    request: DeepPartial<ListUsersRequest>,
    options?: CallOptions & CallOptionsExt
  ): Promise<ListUsersResponse>;
  getUserStatistics(
    request: DeepPartial<GetUserStatisticsRequest>,
    options?: CallOptions & CallOptionsExt
  ): Promise<GetUserStatisticsResponse>;
  /** Подписывается на список пользователей. Ключ подписки – userId */
  subscribeToUsers(
    request: DeepPartial<SubscribeToUsersRequest>,
    options?: CallOptions & CallOptionsExt
  ): AsyncIterable<SubscribeToUsersResponse>;
  getAffiliateDeals(
    request: DeepPartial<GetAffiliateDealsRequest>,
    options?: CallOptions & CallOptionsExt
  ): Promise<GetAffiliateDealsResponse>;
  /** Подписывается на список агентских сделок. Ключ подписки – dealId */
  subscribeToAffiliateDeals(
    request: DeepPartial<SubscribeToAffiliateDealsRequest>,
    options?: CallOptions & CallOptionsExt
  ): AsyncIterable<SubscribeToAffiliateDealsResponse>;
  /** Создает, обновляет или удаляет новую сделку и относящийся к ней линк. */
  saveAffiliateDeal(
    request: DeepPartial<SaveAffiliateDealRequest>,
    options?: CallOptions & CallOptionsExt
  ): Promise<SaveAffiliateDealResponse>;
  /**
   * Возвращает исторические и актуальную версии документа со сделкой.
   * Порядок документов - от новых к старым.
   */
  getDealHistory(
    request: DeepPartial<GetDealHistoryRequest>,
    options?: CallOptions & CallOptionsExt
  ): Promise<GetDealHistoryResponse>;
  /**
   * Возвращает исторические и актуальную версии документа с линком.
   * Порядок документов - от новых к старым.
   */
  getLinkHistory(
    request: DeepPartial<GetLinkHistoryRequest>,
    options?: CallOptions & CallOptionsExt
  ): Promise<GetLinkHistoryResponse>;
  /**
   * Подписывается на все актуальные линки,
   * в том числе линки без привязки к сделке.
   */
  subscribeToLinks(
    request: DeepPartial<SubscribeToLinksRequest>,
    options?: CallOptions & CallOptionsExt
  ): AsyncIterable<SubscribeToLinksResponse>;
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Long
  ? string | number | Long
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string }
  ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & {
      $case: T["$case"];
    }
  : T extends {}
  ? { [K in Exclude<keyof T, "$type">]?: DeepPartial<T[K]> }
  : Partial<T>;

function toTimestamp(date: Date): Timestamp {
  const seconds = numberToLong(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { $type: "google.protobuf.Timestamp", seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds.toNumber() * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function numberToLong(number: number) {
  return Long.fromNumber(number);
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

export type ServerStreamingMethodResult<Response> = {
  [Symbol.asyncIterator](): AsyncIterator<Response, void>;
};
