import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {DateRange, DateRangeInput} from '@blueprintjs/datetime';
import {format, parse} from 'date-fns';
import {stylesheet} from 'typestyle';

export type DateRangeSelectContext = {
  dateRange: DateRange;
  setDateRange: (dateRange: DateRange) => void;
};

const dateRangeSelectContext = createContext<DateRangeSelectContext | null>(
  null,
);
dateRangeSelectContext.displayName = 'DateRangeSelect';

export function useDateRangeSelectContext() {
  const dateRangeSelect = useContext(dateRangeSelectContext);

  if (dateRangeSelect == null) {
    throw new Error('Missing DateRangeSelectProvider');
  }
  return dateRangeSelect;
}

export const DateRangeSelectProvider: React.FC = ({children}) => {
  const [dateRange, setDateRange] = useState<DateRange>([null, null]);

  const value = useMemo(
    () => ({
      dateRange,
      setDateRange,
    }),
    [dateRange],
  );

  return (
    <dateRangeSelectContext.Provider value={value}>
      {children}
    </dateRangeSelectContext.Provider>
  );
};

const styles = stylesheet({
  dateSelectWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 10,
  },
});

export const DateRangeSelect: React.FC = () => {
  const {dateRange, setDateRange} = useDateRangeSelectContext();
  const today = useMemo(() => {
    return new Date();
  }, []);

  const dateFnsFormat = 'dd-MM-yyyy';
  const formatDate = useCallback(
    (date: Date) => format(date, dateFnsFormat),
    [],
  );
  const parseDate = useCallback(
    (str: string) => parse(str, dateFnsFormat, today),
    [today],
  );

  return (
    <div className={styles.dateSelectWrapper}>
      <DateRangeInput
        formatDate={formatDate}
        parseDate={parseDate}
        value={dateRange}
        onChange={selectedDates => setDateRange(selectedDates)}
        maxDate={today}
        shortcuts={false}
        allowSingleDayRange={true}
      />
    </div>
  );
};
