import {createContext, useContext} from 'react';
import {Observable, scan, shareReplay} from 'rxjs';
import {useExchequerClient} from '../client/useExchequerClient';
import {User} from '../proto/deeplay/jackpoker_exchequer/v1/jackpoker_exchequer';
import {fromAsyncGenerator} from 'abort-controller-x-rxjs';

export type ExpandedUser = User & {
  affiliateName: string;
};

export type UsersContextState = {
  usersById: Map<string, ExpandedUser>;
  affiliatesList: ExpandedUser[];
};

export const observableUsersContext = createContext<Observable<UsersContextState> | null>(
  null,
);

export function useUsersObservableContext() {
  const usersContextState = useContext(observableUsersContext);

  if (usersContextState == null) {
    throw new Error('Missing UsersSubscriptionContextProvider');
  }
  return usersContextState;
}

export const INITIAL_STATE: UsersContextState = {
  affiliatesList: [] as ExpandedUser[],
  usersById: new Map<string, ExpandedUser>(),
};

export const UsersObservableContextProvider: React.FC = ({children}) => {
  const exchequerClient = useExchequerClient();

  const usersSubscription$ = fromAsyncGenerator(signal =>
    exchequerClient.subscribeToUsers({}, {signal}),
  ).pipe(
    scan((accumulator, update, index) => {
      // FIXME: Из подписок надо возвращать полный стейт, а не ченджи
      const newState: Map<string, ExpandedUser> = new Map();

      for (const {key, value} of update.updates) {
        if (value != null) {
          newState.set(key, {...value, affiliateName: ''});
        } else {
          newState.delete(key);
        }
      }
      const expandedUsers = new Map<string, ExpandedUser>();
      const affiliateIds = new Set<string>();

      for (const user of newState.values()) {
        if (user.agentId) {
          affiliateIds.add(user.agentId);
        }

        const affiliateName = user.agentId
          ? newState.get(user.agentId)?.name || ''
          : '';

        expandedUsers.set(user.id, {...user, affiliateName});
      }

      const affiliatesList: ExpandedUser[] = [];

      for (const id of affiliateIds) {
        const affiliate = expandedUsers.get(id);

        if (affiliate) {
          affiliatesList.push(affiliate);
        }
      }

      return {usersById: expandedUsers, affiliatesList};
    }, INITIAL_STATE),
    shareReplay({bufferSize: 1, refCount: true}),
  );

  return (
    <observableUsersContext.Provider value={usersSubscription$}>
      {children}
    </observableUsersContext.Provider>
  );
};
