import {Colors} from '@blueprintjs/core';
import {Tooltip2} from '@blueprintjs/popover2';
import {stylesheet} from 'typestyle';

type ColumnHeaderWithTooltipProps = {
  targetContent: string;
  tooltipContent: {title: string; description: JSX.Element};
};

const css = stylesheet({
  tooltipContent: {
    width: 700,
    backgroundColor: Colors.WHITE,
    border: `1px solid ${Colors.LIGHT_GRAY2}`,
    zIndex: 11,
    padding: `0 10px 10px 10px`,
  },
  target: {
    $nest: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
});

export const ColumnHeaderWithTooltip: React.FC<ColumnHeaderWithTooltipProps> = ({
  targetContent,
  tooltipContent: {title, description},
}) => {
  return (
    <Tooltip2
      minimal={true}
      usePortal={true}
      position="bottom"
      content={
        <div className={css.tooltipContent}>
          <h5>{title}</h5>
          {description}
        </div>
      }
      openOnTargetFocus={false}
      renderTarget={({isOpen, ref, ...tooltipProps}) => (
        <th {...tooltipProps} ref={ref} className={css.target}>
          {targetContent}
        </th>
      )}
    />
  );
};
