import React, {memo, useCallback, useState} from 'react';

import {AffiliatesList} from './AffiliatesList';
import {UsersList} from './UsersList';
import {classes as cx, stylesheet} from 'typestyle';
import {
  ExportUserStatisticsContextProvider,
  useExportUserStatisticsContext,
} from './ExportUserStatisticsContext';
import {DateRangeSelectProvider} from './DateRangeSelect';
import {ToolBar} from './ToolBar';

import {useLoadingStatisticsContext} from './LoadingStatisticsContext';
import {
  ExpandedUser,
  INITIAL_STATE,
  UsersContextState,
  useUsersObservableContext,
} from '../../subscriptions/usersObservableContext';
import {useObservableState} from 'observable-hooks';

const css = stylesheet({
  root: {
    display: 'grid',
    gridTemplateColumns: '450px 1fr',
    height: 'calc(100vh - 100px)',
  },
});

export type AffiliatesPageProps = {
  className?: string;
};

export const AffiliatesPageWithProviders = () => {
  return (
    <DateRangeSelectProvider>
      <ExportUserStatisticsContextProvider>
        <AffiliatesPage />
      </ExportUserStatisticsContextProvider>
    </DateRangeSelectProvider>
  );
};

export const AffiliatesPage: React.FC<AffiliatesPageProps> = memo(
  ({className}) => {
    const observable$ = useUsersObservableContext();
    const [{affiliatesList, usersById}] = useObservableState<UsersContextState>(
      () => observable$,
      INITIAL_STATE,
    );

    const {setErrorMessage} = useLoadingStatisticsContext();
    const {setData, setIsDisabled} = useExportUserStatisticsContext();

    const [selectedAffiliateId, setSelectedAffiliateId] = useState<
      string | null
    >(null);
    const handleAffiliateClick = useCallback(
      (affiliate: ExpandedUser) => {
        setIsDisabled(true);
        setData(null);
        setSelectedAffiliateId(affiliate.id);
        setErrorMessage(null);
      },
      [setData, setErrorMessage, setIsDisabled],
    );

    return (
      <>
        <ToolBar />
        <div className={cx(css.root, className)}>
          <AffiliatesList
            affiliatesList={affiliatesList}
            onRowClick={handleAffiliateClick}
            selectedAffiliateId={selectedAffiliateId}
          />
          {selectedAffiliateId && (
            <UsersList
              usersById={usersById}
              selectedAffiliateId={selectedAffiliateId}
            />
          )}
        </div>
      </>
    );
  },
);
