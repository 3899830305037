/* eslint-disable */
import { messageTypeRegistry } from "../../typeRegistry";
import Long from "long";
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "deeplay.common";

/**
 * Основан на
 * https://github.com/googleapis/googleapis/blob/master/google/type/money.proto
 */
export interface Money {
  $type: "deeplay.common.Money";
  /** Целая часть суммы. */
  units: Long;
  /**
   * Десятичные доли суммы.
   * Значение должно быть в пределах от -999,999,999 до +999,999,999
   * включительно.
   * Когда `units` положительный, `nanos` должен быть
   * положительным или равен 0.
   * Когда `units` равен 0, `nanos` может равняться
   * 0, быть положительным или отрицательным.
   * Когда `units` отрицательный,
   * `nanos` должен быть отрицательным или равен 0.
   * Пример: $-1.75 выражается
   * как `units`=-1, `nanos`=-750,000,000.
   */
  nanos: number;
}

function createBaseMoney(): Money {
  return { $type: "deeplay.common.Money", units: Long.ZERO, nanos: 0 };
}

export const Money = {
  $type: "deeplay.common.Money" as const,

  encode(message: Money, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (!message.units.isZero()) {
      writer.uint32(8).int64(message.units);
    }
    if (message.nanos !== 0) {
      writer.uint32(16).int32(message.nanos);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Money {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMoney();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.units = reader.int64() as Long;
          break;
        case 2:
          message.nanos = reader.int32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<Money>): Money {
    const message = createBaseMoney();
    message.units =
      object.units !== undefined && object.units !== null
        ? Long.fromValue(object.units)
        : Long.ZERO;
    message.nanos = object.nanos ?? 0;
    return message;
  },
};

messageTypeRegistry.set(Money.$type, Money);

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Long
  ? string | number | Long
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string }
  ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & {
      $case: T["$case"];
    }
  : T extends {}
  ? { [K in Exclude<keyof T, "$type">]?: DeepPartial<T[K]> }
  : Partial<T>;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
