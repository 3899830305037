/* eslint-disable */
import { messageTypeRegistry } from "../../../typeRegistry";
import { Timestamp } from "../../../google/protobuf/timestamp";
import Long from "long";
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "deeplay.jackpoker_exchequer.v1";

/**
 * Линк - агентская ссылка, по которой в игру приходит аффилированный игрок.
 * Линк привязывается к игроку при регистрации.
 * Каждый линк должен соответствовать одной сделке в определённый период
 * времени.
 */
export interface Link {
  $type: "deeplay.jackpoker_exchequer.v1.Link";
  id: string;
  /** Произвольная строка, привязанная к игроку при регистрации. */
  content: string;
  /** Идентификатор агента игрока, к которому привязан линк. */
  affiliateId: string;
  /** Массив сделок с началом их действия, отсортированный по времеми. */
  assignedDeals: AssignedDeal[];
}

export interface AssignedDeal {
  $type: "deeplay.jackpoker_exchequer.v1.AssignedDeal";
  /**
   * Дата, с которой сделка вступает в силу.
   * Сделка считается закончившейся, если имеется сделка с более поздней
   * start_date.
   */
  startDate: Date | undefined;
  /** Foreign key to Deal */
  dealId: string;
  dealName: string;
}

function createBaseLink(): Link {
  return {
    $type: "deeplay.jackpoker_exchequer.v1.Link",
    id: "",
    content: "",
    affiliateId: "",
    assignedDeals: [],
  };
}

export const Link = {
  $type: "deeplay.jackpoker_exchequer.v1.Link" as const,

  encode(message: Link, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.content !== "") {
      writer.uint32(18).string(message.content);
    }
    if (message.affiliateId !== "") {
      writer.uint32(26).string(message.affiliateId);
    }
    for (const v of message.assignedDeals) {
      AssignedDeal.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Link {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLink();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.content = reader.string();
          break;
        case 3:
          message.affiliateId = reader.string();
          break;
        case 6:
          message.assignedDeals.push(
            AssignedDeal.decode(reader, reader.uint32())
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<Link>): Link {
    const message = createBaseLink();
    message.id = object.id ?? "";
    message.content = object.content ?? "";
    message.affiliateId = object.affiliateId ?? "";
    message.assignedDeals =
      object.assignedDeals?.map((e) => AssignedDeal.fromPartial(e)) || [];
    return message;
  },
};

messageTypeRegistry.set(Link.$type, Link);

function createBaseAssignedDeal(): AssignedDeal {
  return {
    $type: "deeplay.jackpoker_exchequer.v1.AssignedDeal",
    startDate: undefined,
    dealId: "",
    dealName: "",
  };
}

export const AssignedDeal = {
  $type: "deeplay.jackpoker_exchequer.v1.AssignedDeal" as const,

  encode(
    message: AssignedDeal,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.startDate !== undefined) {
      Timestamp.encode(
        toTimestamp(message.startDate),
        writer.uint32(10).fork()
      ).ldelim();
    }
    if (message.dealId !== "") {
      writer.uint32(18).string(message.dealId);
    }
    if (message.dealName !== "") {
      writer.uint32(26).string(message.dealName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssignedDeal {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssignedDeal();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.startDate = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          );
          break;
        case 2:
          message.dealId = reader.string();
          break;
        case 3:
          message.dealName = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<AssignedDeal>): AssignedDeal {
    const message = createBaseAssignedDeal();
    message.startDate = object.startDate ?? undefined;
    message.dealId = object.dealId ?? "";
    message.dealName = object.dealName ?? "";
    return message;
  },
};

messageTypeRegistry.set(AssignedDeal.$type, AssignedDeal);

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Long
  ? string | number | Long
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string }
  ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & {
      $case: T["$case"];
    }
  : T extends {}
  ? { [K in Exclude<keyof T, "$type">]?: DeepPartial<T[K]> }
  : Partial<T>;

function toTimestamp(date: Date): Timestamp {
  const seconds = numberToLong(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { $type: "google.protobuf.Timestamp", seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds.toNumber() * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function numberToLong(number: number) {
  return Long.fromNumber(number);
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
