import {RetryOptions, retryMiddleware} from 'nice-grpc-client-middleware-retry';
import {Client, createChannel, createClientFactory} from 'nice-grpc-web';
import {OidcClient} from '../auth/OidcClient';

import {JackPokerExchequerDefinition} from '../proto/deeplay/jackpoker_exchequer/v1/jackpoker_exchequer';
import {loggerMiddleware} from '../utils/loggerMiddleware';
import {AuthMiddleware} from './AuthMiddleware';

const channel = createChannel(
  window?.process?.env.REACT_APP_GRPC_URL ?? window.location.origin,
);

const clientFactory = createClientFactory()
  .use(loggerMiddleware)
  .use(retryMiddleware);

export type ExchequerClientParams = {
  oidcClient: OidcClient;
};

export function ExchequerClient({oidcClient}: ExchequerClientParams) {
  return clientFactory
    .use(AuthMiddleware({oidcClient}))
    .create(JackPokerExchequerDefinition, channel);
}

export type ExchequerClient = Client<
  typeof JackPokerExchequerDefinition,
  RetryOptions
>;
