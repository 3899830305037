import {Client, ClientError, Status} from 'nice-grpc-web';
import {AbortSignal} from 'node-abort-controller';
import {retryCollectionSubscription} from 'retry-subscription';

import {rootLogger as logger} from '../logger';
import {JackPokerExchequerDefinition} from '../proto/deeplay/jackpoker_exchequer/v1/jackpoker_exchequer';

const retryableStatuses: Status[] = [
  Status.UNKNOWN,
  Status.RESOURCE_EXHAUSTED,
  Status.INTERNAL,
  Status.UNAVAILABLE,
];

export function subscribeToDeals(
  signal: AbortSignal,
  client: Client<typeof JackPokerExchequerDefinition>,
) {
  return retryCollectionSubscription(
    async function* (signal) {
      for await (const response of client.subscribeToAffiliateDeals(
        {},
        {signal},
      )) {
        yield response.updates;
      }
    },
    {
      signal,
      onError(err, attempt, delayMs) {
        console.log({err}, 'Deal subscription went wrong');
        if (
          err instanceof ClientError &&
          !retryableStatuses.includes(err.code)
        ) {
          throw err;
        }

        if (attempt !== undefined) {
          logger.error(
            {err, attempt, delayMs},
            'Failed to subscribe, retrying.',
          );
        } else {
          logger.debug({err}, 'Subscription dropped, resubscribing.');
        }
      },
    },
  );
}
